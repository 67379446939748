import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useAuth() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("pmu_token");

        if (!token) {
            navigate("/");
            return;
        }

        // Check if the token is still valid here...
        const checkTokenValidity = () => {
            var t = localStorage.getItem("pmu_token_expired_on").split(/[- :]/);
            var dt1 = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            var dt2 = new Date();

            var diff =(dt1.getTime() - dt2.getTime()) / 1000;
            diff /= 60;
            var td = Math.round(diff);
            if(td < 0) {
                // localStorage.removeItem('pmu_token');
                // localStorage.removeItem('pmu_token_expired_on');
                // localStorage.removeItem('pmu_user');
                // setUserInfo(null);
                // navigate("/");
                // return false;
            }  
            else {
                return true;
            }
        }
        const isTokenValid = checkTokenValidity(token);

        if (!isTokenValid) {
            localStorage.removeItem("pmu_token");
            navigate("/");
        }
    }, [navigate]);

    // Return any data that you want to make available to your components
    return {
        isAuthenticated: !!localStorage.getItem("pmu_token"),
    };
}

export default useAuth;