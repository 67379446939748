import { useState, useEffect } from "react";
import { Button, Card, Badge, ProgressBar } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { API_ACTIVITIES_VIEW } from "../../config/ApiList";
import { getFormatedDateTime } from "../../helpers/util";

function ViewActivity(props) {
  const { activityRef } = props;
  const { state } = useLocation();
  const projectRef = state.projectRef;

  const navigate = useNavigate();

  const [completionPC, setCompletionPC] = useState(0);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [apiData, setApiData] = useState(null);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    props
      .callRequest("GET", API_ACTIVITIES_VIEW + "/" + activityRef, true, null)
      .then((res) => {
        setApiData(res.data.activity);
        const pc =
          res.data.activity.active_status.length === 0
            ? 0
            : res.data.activity.active_status.as_completation;
        setCompletionPC(pc);
        setAssignedUsers(res.data.activity.assign);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>View Activitiy</h5>
          <div className="d-flex">
            <Button
              className="link-action me-2"
              onClick={() => navigate(`/projects/view/${projectRef}`)}
            >
              Back to Projects
            </Button>
            {user.role === "LEADER" || user.role === "ADMIN" ? null : (
              <Button
                className="link-action"
                onClick={() => navigate(`/activities/edit/${activityRef}`)}
              >
                Edit Activity
              </Button>
            )}
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-12 col-lg-12">
                  <div>
                    <div className="d-flex align-items-center">
                      <h4 className="fw-bold m-0 me-2">
                        {apiData.activity_name}
                      </h4>
                      <Badge bg="secondary">{apiData.activity_status}</Badge>
                    </div>
                    <h5 className="d-flex align-items-center my-2 ">
                      <i className="las la-laptop-code me-2"></i>
                      <Link
                        to={`/projects/view/${apiData.project.project_ref}`}
                      >
                        {apiData.project.project_title}
                      </Link>
                    </h5>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 info">
                  <h5 className="fw-bold">
                    Total Progress <span>{completionPC}%</span>
                  </h5>
                  <ProgressBar animated variant="info" now={completionPC} />
                </div> */}

                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold">Planned Start</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.activity_plan_start_date,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-day text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold">Deadline</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.activity_plan_end_date,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {apiData.activity_revised_end_date ? (
                  <div className="col-xl-3 col-sm-6 mt-4">
                    <div className="d-flex">
                      <span>
                        <i className="las la-calendar-day text-primary me-2"></i>
                      </span>
                      <div>
                        <h5 className="fw-bold">Revised End Date</h5>
                        <span>
                          {getFormatedDateTime(
                            apiData.activity_revised_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {apiData.activity_actual_end_date ? (
                  <div className="col-xl-3 col-sm-6 mt-4">
                    <div className="d-flex">
                      <span>
                        <i className="las la-calendar-day text-primary me-2"></i>
                      </span>
                      <div>
                        <h5 className="fw-bold">Actual End Date</h5>
                        <span>
                          {getFormatedDateTime(
                            apiData.activity_actual_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-xl-3 col-sm-6 mt-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-users text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold">Assigned To</h5>
                      {assignedUsers.map((u, i) => {
                        return (
                          <span className="me-3" key={i}>
                            {u.user.u_fullname}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row project-description border-bottom py-3">
                <p>{apiData.activity_description}</p>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </Card>
    </>
  );
}

export default ViewActivity;
