import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import {
  API_LISTS_USER,
  API_LISTS_PROJECT,
  API_ISSUE_CREATE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

const NewIssuesForm = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const projectId = state !== null && state.projectId ? state.projectId : "";
  const projectRef = state.projectRef;

  const projectStartDate = state.projectStartDate;
  const projectEndDate = state.projectEndDate;
  const projectRevisedEndDate = state.projectRevisedEndDate;

  const priorityOptions = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
  };

  const [formValues, setFormValues] = useState({
    issue_name: "",
    project_id: projectId ? projectId : "",
    issue_detect_date: "",
    issue_target_date: "",
    issue_assigned_to: "",
    remarks: "",
    issue_status: "OPEN",
    issue_priority: "LOW",
    project_ref: projectRef,
  });
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetchAssigneeOptionsData();
    fetchProjectOptionsData();
  }, []);

  const fetchAssigneeOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_USER, true, null)
      .then((res) => {
        setAssigneeOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchProjectOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_PROJECT, true, null)
      .then((res) => {
        setProjectOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateIssuesSubmit = () => {
    const {
      issue_name,
      issue_detect_date,
      issue_target_date,
      issue_assigned_to,
      remarks,
      issue_priority,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (issue_name === "") {
      isValid = false;
      errors.issue_name = "Issue description is required";
    }

    if (issue_assigned_to === "") {
      isValid = false;
      errors.issue_assigned_to = "Issue owner is required";
    }

    if (issue_detect_date === "") {
      isValid = false;
      errors.issue_detect_date = "Raised on date is required";
    }

    if (issue_target_date === "") {
      isValid = false;
      errors.issue_target_date = "Target completion date is required";
    } else if (issue_target_date < issue_detect_date) {
      isValid = false;
      errors.issue_target_date =
        "Issue target date can not be less than detect date";
    }

    if (remarks === "") {
      isValid = false;
      errors.remarks = "Issue resolution is required";
    }

    if (issue_priority === "") {
      isValid = false;
      errors.issue_priority = "Priority is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleIssuesChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleIssuesSubmit = (e) => {
    e.preventDefault();
    if (!validateIssuesSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ISSUE_CREATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/projects/view/${projectRef}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>New Issue</h5>
              <div>
                <Button
                  className="link-action"
                  onClick={() => navigate(`/projects/view/${projectRef}`)}
                >
                  Back to Projects
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form onSubmit={handleIssuesSubmit}>
        <Row className="mb-3">
          <Col lg={8}>
            <Form.Group controlId="issue_name">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="issue_name"
                placeholder="Enter Issue Description"
                autoComplete="off"
                value={formValues.issue_name}
                onChange={handleIssuesChange}
              />
              <small className="error">{formErrors.issue_name}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="issue_assigned_to">
              <Form.Label>Owner</Form.Label>
              <Form.Select
                name="issue_assigned_to"
                autoComplete="off"
                value={formValues.issue_assigned_to}
                onChange={handleIssuesChange}
              >
                <option value="">Select Owner</option>
                {assigneeOptions.map((key) => (
                  <option key={key.u_id} value={key.u_id}>
                    {key.u_fullname}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.issue_assigned_to}</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="project_id">
              <Form.Label>Project</Form.Label>
              <Form.Select
                name="project_id"
                value={formValues.project_id}
                onChange={handleIssuesChange}
                disabled={projectId ? true : false}
              >
                <option value="">Select Project</option>
                {Object.keys(projectOptions).map((key) => (
                  <option key={key} value={key}>
                    {projectOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.project_id}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="issue_detect_date">
              <Form.Label>Raised On</Form.Label>
              <Form.Control
                type="date"
                name="issue_detect_date"
                autoComplete="off"
                value={formValues.issue_detect_date}
                onChange={handleIssuesChange}
                min={projectStartDate}
                max={
                  projectRevisedEndDate ? projectRevisedEndDate : projectEndDate
                }
              />
              <small className="error">{formErrors.issue_detect_date}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="issue_target_date">
              <Form.Label>Target Completion Date</Form.Label>
              <Form.Control
                type="date"
                name="issue_target_date"
                autoComplete="off"
                value={formValues.issue_target_date}
                onChange={handleIssuesChange}
                min={formValues.issue_detect_date}
                max={
                  projectRevisedEndDate ? projectRevisedEndDate : projectEndDate
                }
              />
              <small className="error">{formErrors.issue_target_date}</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={9}>
            <Form.Group controlId="remarks">
              <Form.Label>Resolution</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="remarks"
                placeholder="Enter Issue Resolution"
                autoComplete="off"
                value={formValues.remarks}
                onChange={handleIssuesChange}
              />
              <small className="error">{formErrors.remarks}</small>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="issue_priority">
              <Form.Label>Priority</Form.Label>
              <Form.Select
                name="issue_priority"
                value={formValues.issue_priority}
                onChange={handleIssuesChange}
              >
                <option value="">Select Priority</option>
                {Object.keys(priorityOptions).map((key) => (
                  <option key={key} value={key}>
                    {priorityOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.issue_priority}</small>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-2 text-end">
          <Button type="submit" className="btn btn-primary mt-2">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewIssuesForm;
