import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { API_PROJECTS } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function ProjectCount(props) {
  const [projectCount, setProjectCount] = useState(0);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = () => {
    props
      .callRequest("GET", API_PROJECTS, true, null)
      .then((res) => {
        setProjectCount(res.data.count);
      })
      .catch((e) => {
        showDialog("error", e.response.data.message, 2000);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Link to="/projects">
            <div className="row d-flex align-items-center">
              <div className="col-4">
                <i className="las la-laptop-code card-icon-lg"></i>
              </div>
              <div className="col-8">
                <h4 className="text-muted fw-bold m-0">Projects</h4>
                <h2 className="fw-bolder m-0">{projectCount}</h2>
              </div>
            </div>
          </Link>
        </Card.Body>
      </Card>
    </>
  );
}

export default ProjectCount;
