import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import { API_ISSUES } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function IssueCount(props) {
  const [issueCount, setIssueCount] = useState(0);

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = () => {
    props
      .callRequest("GET", API_ISSUES, true, null)
      .then((res) => {
        setIssueCount(res.data.count);
      })
      .catch((e) => {
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row d-flex align-items-center">
            <div className="col-4">
              <i className="las la-bug card-icon-lg"></i>
            </div>
            <div className="col-8">
              <h4 className="text-muted fw-bold m-0">Issues</h4>
              <h2 className="fw-bolder m-0">{issueCount}</h2>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default IssueCount;
