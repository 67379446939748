import { apiDomain, webDomain } from "../helpers/util";

export const API_DOMAIN = apiDomain() + "/";
export const API_WEB_DOMAIN = webDomain();

//Login
export const API_AUTHENTICATE = API_DOMAIN + "authenticate";
export const API_VALIDATE_SECURITY_CODE = API_DOMAIN + "validate-security-code";

//Dashboard
export const API_ACTIVITIES = API_DOMAIN + "activities";
export const API_PROJECTS = API_DOMAIN + "projects";
export const API_RISKS = API_DOMAIN + "risks";
export const API_UPCOMING_ACTIVITIES = API_DOMAIN + "upcoming-activities";

//Projects
export const API_LISTS_DEPARTMENT = API_DOMAIN + "lists/department";
export const API_LISTS_SI = API_DOMAIN + "lists/si";
export const API_LISTS_FINANCIAL_YEAR = API_DOMAIN + "lists/financial-year";
export const API_LISTS_DOMAIN = API_DOMAIN + "lists/domain";
export const API_LISTS_LEAD = API_DOMAIN + "lists/lead";
export const API_LISTS_SPOC = API_DOMAIN + "lists/spoc";
export const API_LISTS_MANAGER = API_DOMAIN + "lists/manager";
export const API_LISTS_SME = API_DOMAIN + "lists/sme";
export const API_LISTS_ANALYST = API_DOMAIN + "lists/analyst";
export const API_USERS = API_DOMAIN + "users";
export const API_PROJECTS_CREATE = API_DOMAIN + "projects/create";
export const API_PROJECTS_VIEW = API_DOMAIN + "projects/view";
export const API_FETCH_PROJECT_DATA = API_DOMAIN + "projects/edit";
export const API_PROJECTS_UPDATE = API_DOMAIN + "projects/update";

//Activities
export const API_LISTS_USER = API_DOMAIN + "lists/user";
// export const API_LISTS_ACTIVITY_USER = API_DOMAIN + "list/activity-users"
export const API_LISTS_PROJECT = API_DOMAIN + "lists/project";
export const API_ACTIVITIES_CREATE = API_DOMAIN + "activities/create";
export const API_ACTIVITIES_VIEW = API_DOMAIN + "activities/view";
export const API_FETCH_ACTIVITY_DATA = API_DOMAIN + "activities/edit";
export const API_ACTIVITIES_UPDATE = API_DOMAIN + "activities/update";

//Risks
// export const API_LISTS_ACTIVITIES = API_DOMAIN + "activities-list";
export const API_RISK_CREATE = API_DOMAIN + "risks/create";
export const API_RISKS_VIEW = API_DOMAIN + "risks/view";
export const API_FETCH_RISK_DATA = API_DOMAIN + "risks/edit";
export const API_RISKS_UPDATE = API_DOMAIN + "risks/update";
export const API_RISKS_BY_PROJECT = API_DOMAIN + "risks-by-project";

//Issues
export const API_ISSUES = API_DOMAIN + "issues";
//export const API_PROJECT_MEMBERS = API_DOMAIN + "project-members";
export const API_ISSUE_CREATE = API_DOMAIN + "issues/create";
export const API_FETCH_ISSUE_DATA = API_DOMAIN + "issues/edit";
export const API_ISSUE_UPDATE = API_DOMAIN + "issues/update";
export const API_ISSUES_VIEW = API_DOMAIN + "issues/view";

//Actions
export const API_ACTIONS = API_DOMAIN + "actions";
export const API_ACTION_CREATE = API_DOMAIN + "actions/create";
export const API_ACTION_VIEW = API_DOMAIN + "actions/view";
export const API_FETCH_ACTION_DATA = API_DOMAIN + "actions/edit";
export const API_ACTIONS_UPDATE = API_DOMAIN + "actions/update";

//Users
export const API_LISTS_ROLE = API_DOMAIN + "lists/role";
export const API_USER_CREATE = API_DOMAIN + "users/create";
export const API_USERS_VIEW = API_DOMAIN + "users/view";
export const API_FETCH_USER_DATA = API_DOMAIN + "users/edit";
export const API_USERS_UPDATE = API_DOMAIN + "users/update";

//Roles & Permissions
export const API_ROLES = API_DOMAIN + "roles";
export const API_ROLE_CREATE = API_DOMAIN + "roles/create";
export const API_ROLES_VIEW = API_DOMAIN + "roles/view";
export const API_FETCH_ROLES_DATA = API_DOMAIN + "roles/edit";
export const API_ROLE_UPDATE = API_DOMAIN + "roles/update";
export const API_LISTS_URLGROUP = API_DOMAIN + "lists/urlgroup";
export const API_ROLES_PERMISSIONS = API_DOMAIN + "roles/permissions";
export const API_ROLES_PERMISSIONS_SAVE = API_DOMAIN + "roles/permissions/save";
