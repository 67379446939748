import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { API_USERS_VIEW } from "../../config/ApiList";
import { showDialog, webDomain, getBirthday } from "../../helpers/util";

function ViewUser(props) {
  const { userRef } = props;
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    props
      .callRequest("GET", API_USERS_VIEW + "/" + userRef, true, null)
      .then((res) => {
        setApiData(res.data.user);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>View User</h5>
        </div>

        <div className="card-body">
          {apiData ? (
            <div className="profile-view mb-3">
              <div className="profile-img-wrap">
                <div className="profile-img">
                  <a href="#">
                    {apiData.u_profile_photo === null ? (
                      <img
                        src="/assets/images/avatar.jpg"
                        className="me-3"
                      />
                    ) : (
                      <img
                        src={webDomain() + apiData.u_profile_photo}
                        alt={apiData?.u_fullname}
                        className="me-3"
                      />
                    )}
                  </a>
                </div>
              </div>

              <div className="profile-basic">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-info-left pb-3">
                      <h3 className="user-name m-t-0 mb-0">
                        {apiData?.u_fullname}
                      </h3>
                      <small className="text-muted fw-bold">
                        {apiData?.role.r_name}
                      </small>
                      <div className="staff-id">
                        Employee ID: &nbsp;
                        {apiData?.u_emp_code ? apiData?.u_emp_code : "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <ul className="personal-info">
                      <li>
                        <div className="title">Phone:</div>
                        <div className="text">
                          <a href={`tel:${apiData?.phone_1}`}>
                            {apiData?.u_phone}
                          </a>
                          &nbsp;/&nbsp;
                          <a href={`tel:${apiData?.phone_2}`}>
                            {apiData?.u_phone2}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="title">Email:</div>
                        <div className="text">
                          {apiData?.u_email}
                          <br />
                          {apiData?.u_email_personal}
                        </div>
                      </li>
                      <li>
                        <div className="title">Gender:</div>
                        <div className="text">
                          {apiData?.u_gender ? apiData?.u_gender : "N/A"}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
