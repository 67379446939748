import { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showDialog } from "../../helpers/util";
import { useSelector } from "react-redux";

import {
  API_FETCH_RISK_DATA,
  API_RISKS_UPDATE,
  API_LISTS_PROJECT,
} from "../../config/ApiList";

function EditRisk(props) {
  const { riskRef } = props;
  const navigate = useNavigate();

  const { project } = useSelector((state) => state.project);

  const riskStatusOptions = {
    OPEN: "OPEN",
    WIP: "WIP",
    ON_HOLD: "ON HOLD",
    DEFERRED: "DEFERRED",
    RE_OPEN: "RE-OPEN",
    CLOSED: "CLOSED",
    MONITOR: "MONITOR",
  };

  const riskFactorOptions = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
    CRITICAL: "CRITICAL",
  };

  const [formValues, setFormValues] = useState({
    risk_name: "",
    project_id: "",
    identified_on: "",
    target_closure_date: "",
    revised_closure_date: "",
    actual_closure_date: "",
    risk_mitigation: "",
    risk_status: "",
    risk_factor: "",
    risk_ref: riskRef,
  });
  const [apiData, setApiData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [projectOptions, setProjectOptions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateSubmit = () => {
    const {
      risk_name,
      identified_on,
      target_closure_date,
      revised_closure_date,
      actual_closure_date,
      risk_mitigation,
      risk_status,
      risk_factor,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (risk_name.length === 0) {
      isValid = false;
      errors.risk_name = "Risk description is required";
    }

    if (identified_on === "") {
      isValid = false;
      errors.identified_on = "Identification date is required";
    }

    if (target_closure_date === "") {
      isValid = false;
      errors.target_closure_date = "Target closure date is required";
    }

    if (risk_mitigation === "") {
      isValid = false;
      errors.risk_mitigation = "Risk mitigation is required";
    }

    if (risk_status === "") {
      isValid = false;
      errors.risk_status = "Risk status is required";
    }

    if (risk_factor === "") {
      isValid = false;
      errors.risk_factor = "Risk factor is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleRiskSubmit = (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_RISKS_UPDATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message);
        navigate(`/projects/view/${project.project_ref}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  useEffect(() => {
    fetchData();
    fetchProjectOptionsData();
  }, []);

  const fetchProjectOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_PROJECT, true, null)
      .then((res) => {
        setProjectOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 3000);
      });
  };

  const fetchData = () => {
    props
      .callRequest("GET", API_FETCH_RISK_DATA + "/" + riskRef, true, null)
      .then((res) => {
        setApiData(res.data.risk);
        setFormValues({
          ...formValues,
          risk_name: res.data.risk.risk_name,
          project_id: res.data.risk.risk_project_id,
          identified_on: res.data.risk.risk_identify_date,
          target_closure_date: res.data.risk.risk_target_date,
          revised_closure_date: res.data.risk.risk_revise_date,
          actual_closure_date: res.data.risk.risk_actual_date,
          risk_mitigation: res.data.risk.risk_mitigation,
          risk_status: res.data.risk.risk_status,
          risk_factor: res.data.risk.risk_factor,
        });
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>Edit Risk</h5>
          <div>
            <Button
              className="link-action"
              onClick={() => navigate(`/projects/view/${project.project_ref}`)}
            >
              Back to Projects
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Form onSubmit={handleRiskSubmit}>
            <Row className="mb-3">
              <Col lg={12}>
                <Form.Group controlId="risk_name">
                  <Form.Label>Risk Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="risk_name"
                    placeholder="Enter Risk Description"
                    autoComplete="off"
                    value={formValues.risk_name}
                    onChange={handleChange}
                  />
                  <small className="error">{formErrors.risk_name}</small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_id">
                  <Form.Label>Project</Form.Label>
                  <Form.Select
                    name="project_id"
                    value={formValues.project_id}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="">Select Project</option>
                    {Object.keys(projectOptions).map((key) => (
                      <option key={key} value={key}>
                        {projectOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="identified_on">
                  <Form.Label>Identified On</Form.Label>
                  <Form.Control
                    type="date"
                    name="identified_on"
                    autoComplete="off"
                    value={formValues.identified_on}
                    onChange={handleChange}
                    disabled
                  />
                  <small className="error">{formErrors.identified_on}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="target_closure_date">
                  <Form.Label>Target Closure Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="target_closure_date"
                    autoComplete="off"
                    value={formValues.target_closure_date}
                    onChange={handleChange}
                    disabled
                  />
                  <small className="error">
                    {formErrors.target_closure_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="revised_closure_date">
                  <Form.Label>Revised Closure Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="revised_closure_date"
                    autoComplete="off"
                    value={formValues.revised_closure_date}
                    onChange={handleChange}
                    min={formValues.identified_on}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.revised_closure_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="actual_closure_date">
                  <Form.Label>Actual Closure Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="actual_closure_date"
                    autoComplete="off"
                    value={formValues.actual_closure_date}
                    onChange={handleChange}
                    min={formValues.identified_on}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.actual_closure_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="risk_mitigation">
                  <Form.Label>Mitigation Plan</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="risk_mitigation"
                    placeholder="Enter risk mitigation plan"
                    autoComplete="off"
                    value={formValues.risk_mitigation}
                    onChange={handleChange}
                  />
                  <small className="error">{formErrors.risk_mitigation}</small>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="risk_status">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="risk_status"
                    value={formValues.risk_status}
                    onChange={handleChange}
                  >
                    <option value="">Select Status</option>
                    {Object.keys(riskStatusOptions).map((key) => (
                      <option key={key} value={key}>
                        {riskStatusOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.risk_status}</small>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="risk_factor">
                  <Form.Label>Impact</Form.Label>
                  <Form.Select
                    name="risk_factor"
                    value={formValues.risk_factor}
                    onChange={handleChange}
                  >
                    <option value="">Select Impact</option>
                    {Object.keys(riskFactorOptions).map((key) => (
                      <option key={key} value={key}>
                        {riskFactorOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.risk_factor}</small>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-2 text-end">
              <Button type="submit" className="btn btn-primary mt-2">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
}

export default EditRisk;
