import moment from "moment";

export function showDialog(type, message, timeout = 3000) {
  var classname = "flash-message alert";

  if (type === "success") {
    classname += " alert-success";
  } else if (type === "error") {
    classname += " alert-danger";
  } else if (type === "warning") {
    classname += " alert-warning";
  }

  var div = document.createElement("div");
  div.className = classname;
  //var content = "<i class='las la-times flash-msg-close'></i>";
  //content += message;
  div.innerHTML = message;
  document.body.appendChild(div);

  setTimeout(() => {
    var elem = document.querySelector(".flash-message");
    elem.parentNode.removeChild(elem);
  }, timeout);
}

export function getCurrentMonth() {
  let month = moment().format("MMMM");
  return month;
}

export function getCurrentYear() {
  let year = moment().format("YYYY");
  return year;
}

export function getCurrentMonthYear() {
  let month_year = moment().format("MMMM-YYYY");
  return month_year;
}

export function getCurrentDate(dateFormat = "DD/MM/YYYY") {
  let today = moment().format(dateFormat);
  return today;
}

export function getNextDays(n = 7) {
  let days = [];
  let daysRequired = n;

  for (let i = 0; i < daysRequired; i++) {
    days.push(moment().add(i, "days").format("dddd,DD-MM-YYYY"));
  }
  return days;
}

export function getBirthday(datetime) {
  const dateTime = moment(datetime);
  const formattedDateTime = dateTime.format("MMMM, DD");
  return formattedDateTime;
}

export function getCurrentFinancialYear() {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  let financialYear;

  // Check if the current month is after March (which marks the end of the financial year)
  if (currentMonth >= 2) {
    financialYear = `${currentYear}-${currentYear + 1}`;
  } else {
    financialYear = `${currentYear - 1}-${currentYear}`;
  }

  return financialYear;
}

export function getFormatedDateTime(datetime, format = "hh:mm A, DD/MM/YYYY") {
  const dateTime = moment(datetime);
  const formattedDateTime = dateTime.format(format);
  return formattedDateTime;
}

export function getExcerpt(text, len = 120) {
  if (text === "") {
    return text;
  } else {
    if (text.length > len) {
      let excerpt = text.substr(0, len) + " ...";
      return excerpt;
    } else {
      return text;
    }
  }
}

export function ucFirst(text) {
  const textCapitalized = text.charAt(0).toUpperCase() + text.slice(1);
  return textCapitalized;
}

export function getProjectStatus(status) {
  if (status === "DRAFT") {
    return "<span className='text-warning'>DRAFT</span>";
  } else if (status === "APPROVED") {
    return "<span className='text-danger'>CANCELLED</span>";
  } else if (status === "UPCOMING") {
    return "<span className='text-success'>CONFIRMED</span>";
  } else if (status === "ONGOING") {
    return "<span className='text-success'>CONFIRMED</span>";
  }
}

export function getRiskSeverity(risk_factor) {
  let badgeClass = "";

  if (risk_factor === "LOW") {
    badgeClass = "low";
  } else if (risk_factor === "MEDIUM") {
    badgeClass = "medium";
  } else if (risk_factor === "HIGH") {
    badgeClass = "high";
  } else {
    badgeClass = "critical";
  }

  return (
    <span className={`fw-bold badge bg-${badgeClass}`}>{risk_factor}</span>
  );
}

export function getIssuesSeverity(issue_status) {
  let badgeClass = "";
  let issue_status_str = "";
  if (issue_status === "0") {
    badgeClass = "low";
    issue_status_str = "CLOSED";
  } else if (issue_status === "1") {
    badgeClass = "medium";
    issue_status_str = "OPEN";
  } else if (issue_status === "2") {
    badgeClass = "high";
    issue_status_str = "FIXED";
  } else if (issue_status === "3") {
    badgeClass = "critical";
    issue_status_str = "REOPEN";
  } else {
    badgeClass = "critical";
  }

  return (
    <span className={`fw-bold badge bg-${badgeClass}`}>{issue_status_str}</span>
  );
}

export function getLowerCase(text) {
  return text.toLowerCase();
}

export function apiDomain() {
	//return "http://pmu.itewb.test/api";
	return "https://prakalpaapi.aranax.in/api";
}

export function webDomain() {
	//return "http://pmu.itewb.test/";
	return "https://prakalpaapi.aranax.in/";
}
