import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { showDialog } from "../../helpers/util";
import { useSelector } from "react-redux";

import {
  API_LISTS_USER,
  API_LISTS_PROJECT,
  API_FETCH_ISSUE_DATA,
  API_ISSUE_UPDATE,
} from "../../config/ApiList";

const EditIssues = (props) => {
  const { issueRef } = props;
  const navigate = useNavigate();

  const { project } = useSelector((state) => state.project);

  const statusOptions = {
    OPEN: "OPEN",
    WIP: "WIP",
    ON_HOLD: "ON HOLD",
    DEFERRED: "DEFERRED",
    RE_OPEN: "RE-OPEN",
    CLOSED: "CLOSED",
  };

  const priorityOptions = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
  };

  const [formValues, setFormValues] = useState({
    issue_name: "",
    project_id: "",
    issue_detect_date: "",
    issue_target_date: "",
    issue_revise_date: "",
    issue_resolve_date: "",
    issue_assigned_to: "",
    remarks: "",
    issue_status: "",
    issue_priority: "",
    issue_ref: issueRef,
    project_ref: project.project_ref,
  });

  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  const [apiData, setApiData] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const handleIssuesChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateIssuesSubmit = () => {
    const {
      issue_name,
      issue_detect_date,
      issue_target_date,
      issue_revise_date,
      issue_resolve_date,
      issue_assigned_to,
      remarks,
      issue_status,
      issue_priority,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (issue_name === "") {
      isValid = false;
      errors.issue_name = "Issue description is required";
    }

    if (issue_assigned_to === "") {
      isValid = false;
      errors.issue_assigned_to = "Issue owner is required";
    }

    if (remarks === "") {
      isValid = false;
      errors.remarks = "Issue resolution is required";
    }

    if (issue_status === "") {
      isValid = false;
      errors.issue_status = "Status is required";
    } else if (issue_status === "CLOSED" && issue_resolve_date === null) {
      isValid = false;
      errors.issue_resolve_date = "Actual completion date is required";
    }

    if (issue_priority === "") {
      isValid = false;
      errors.issue_priority = "Priority is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  useEffect(() => {
    fetchAssigneeOptionsData();
    fetchProjectOptionsData();
  }, []);

  const fetchAssigneeOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_USER, true, null)
      .then((res) => {
        setAssigneeOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchProjectOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_PROJECT, true, null)
      .then((res) => {
        setProjectOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchIssuesData();
  }, []);

  const fetchIssuesData = () => {
    props
      .callRequest("GET", API_FETCH_ISSUE_DATA + "/" + issueRef, true, null)
      .then((res) => {
        setApiData(res.data.issue);
        setFormValues({
          ...formValues,
          issue_name: res.data.issue.issue_name,
          project_id: res.data.issue.project.project_id,
          issue_detect_date: res.data.issue.issue_detect_date,
          issue_target_date: res.data.issue.issue_target_date,
          issue_revise_date: res.data.issue.issue_revise_date,
          issue_resolve_date: res.data.issue.issue_resolve_date,
          remarks: res.data.issue.remarks,
          issue_assigned_to: res.data.issue.user.u_id,
          issue_status: res.data.issue.issue_status,
          issue_priority: res.data.issue.issue_priority,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleIssuesSubmit = (e) => {
    e.preventDefault();
    if (!validateIssuesSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ISSUE_UPDATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/projects/view/${project.project_ref}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>Edit Issue</h5>
          <div>
            <Button
              className="link-action"
              onClick={() => navigate(`/projects/view/${project.project_ref}`)}
            >
              Back to Projects
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Form onSubmit={handleIssuesSubmit}>
            <Row className="mb-3">
              <Col lg={8}>
                <Form.Group controlId="issue_name">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="issue_name"
                    placeholder="Enter Issue description"
                    autoComplete="off"
                    value={formValues.issue_name}
                    onChange={handleIssuesChange}
                  />
                  <small className="error">{formErrors.issue_name}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="issue_assigned_to">
                  <Form.Label>Owner</Form.Label>
                  <Form.Select
                    name="issue_assigned_to"
                    value={formValues.issue_assigned_to}
                    onChange={handleIssuesChange}
                  >
                    <option value="">Select Owner</option>
                    {assigneeOptions.map((key) => (
                      <option key={key.u_id} value={key.u_id}>
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formErrors.issue_assigned_to}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_id">
                  <Form.Label>Project</Form.Label>
                  <Form.Select
                    name="project_id"
                    value={formValues.project_id}
                    onChange={handleIssuesChange}
                    disabled
                  >
                    <option value="">Select Project</option>
                    {Object.keys(projectOptions).map((key) => (
                      <option key={key} value={key}>
                        {projectOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_id}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="issue_detect_date">
                  <Form.Label>Raised On</Form.Label>
                  <Form.Control
                    type="date"
                    name="issue_detect_date"
                    autoComplete="off"
                    value={formValues.issue_detect_date}
                    onChange={handleIssuesChange}
                    disabled
                  />
                  <small className="error">
                    {formErrors.issue_detect_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="issue_target_date">
                  <Form.Label>Target Completion Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="issue_target_date"
                    autoComplete="off"
                    value={formValues.issue_target_date}
                    onChange={handleIssuesChange}
                    disabled
                  />
                  <small className="error">
                    {formErrors.issue_target_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="issue_revise_date">
                  <Form.Label>Revised Completion Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="issue_revise_date"
                    autoComplete="off"
                    value={formValues.issue_revise_date}
                    onChange={handleIssuesChange}
                    min={formValues.issue_detect_date}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.issue_revise_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="issue_resolve_date">
                  <Form.Label>Actual Completion Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="issue_resolve_date"
                    autoComplete="off"
                    value={formValues.issue_resolve_date}
                    onChange={handleIssuesChange}
                    min={formValues.issue_detect_date}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.issue_resolve_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={6}>
                <Form.Group controlId="remarks">
                  <Form.Label>Resolution</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="remarks"
                    placeholder="Enter issue resolution"
                    autoComplete="off"
                    value={formValues.remarks}
                    onChange={handleIssuesChange}
                  />
                  <small className="error">{formErrors.remarks}</small>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="issue_status">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="issue_status"
                    value={formValues.issue_status}
                    onChange={handleIssuesChange}
                  >
                    <option value="">Select Status</option>
                    {Object.keys(statusOptions).map((key) => (
                      <option key={key} value={key}>
                        {statusOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.issue_status}</small>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="issue_priority">
                  <Form.Label>Priority</Form.Label>
                  <Form.Select
                    name="issue_priority"
                    value={formValues.issue_priority}
                    onChange={handleIssuesChange}
                  >
                    <option value="">Select Priority</option>
                    {Object.keys(priorityOptions).map((key) => (
                      <option key={key} value={key}>
                        {priorityOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.issue_priority}</small>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-2 text-end">
              <Button type="submit" className="btn btn-primary mt-2">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default EditIssues;
