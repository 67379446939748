import moment from "moment";
import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  API_LISTS_DEPARTMENT,
  API_LISTS_SI,
  API_LISTS_LEAD,
  API_LISTS_FINANCIAL_YEAR,
  API_LISTS_DOMAIN,
  API_LISTS_MANAGER,
  API_FETCH_PROJECT_DATA,
  API_PROJECTS_UPDATE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function EditProject(props) {
  const { projectRef } = props;
  const navigate = useNavigate();

  const [deptOptions, setDeptOptions] = useState([]);
  const [siOptions, setSiOptions] = useState([]);
  const [finYearOptions, setFinYearOptions] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [leadOptions, setLeadOptions] = useState([]);

  const colorOptions = [
    { name: "red", colorCode: "#FF0000" },
    { name: "amber", colorCode: "#FFBF00" },
    { name: "green", colorCode: "#00FF00" },
  ];

  const [formValues, setFormValues] = useState({
    project_title: "",
    project_unit: "",
    project_si: "",
    project_fy: "",
    project_domain: "",
    project_spoc: "",
    project_lead: "",
    project_start_date: "",
    project_end_date: "",
    project_revised_end_date: "",
    project_actual_end_date: "",
    project_pmu_manager: "",
    project_pmu_sme: "",
    project_pmu_analyst: "",
    project_total_amount: "",
    project_fund_consumed: "0",
    project_description: "",
    project_status: "",
    project_rag_status: "",
    project_ref: projectRef,
  });
  const [formErrors, setFormErrors] = useState({});
  const [apiData, setApiData] = useState(null);
  const [roles, setRoles] = useState(["SUPERADMIN", "ADMIN"]);

  let user_role = "";
  if (localStorage.getItem("pmu_user") !== null) {
    const user = JSON.parse(localStorage.getItem("pmu_user"));
    user_role = user.role;
  }

  useEffect(() => {
    fetchProjectData();
  }, [projectRef]);

  const fetchProjectData = () => {
    props
      .callRequest("GET", API_FETCH_PROJECT_DATA + "/" + projectRef, true, null)
      .then((res) => {
        setApiData(res.data.project);
        setFormValues({
          ...formValues,
          project_title: res.data.project.project_title,
          project_unit: res.data.project.project_unit,
          project_domain:
            res.data.project.project_domain !== null
              ? res.data.project.project_domain.pd_id
              : "",
          project_spoc: res.data.project.project_spoc.u_id,
          project_fy: res.data.project.project_fy,
          project_start_date: res.data.project.project_start_date,
          project_end_date: res.data.project.project_end_date,
          project_revised_end_date: res.data.project.project_revised_end_date,
          project_actual_end_date: res.data.project.project_actual_end_date,
          project_pmu_manager:
            res.data.project.project_manager !== null
              ? res.data.project.project_manager.u_id
              : "",
          project_total_amount: res.data.project.project_total_amount,
          project_fy_amount: res.data.project.project_fy_amount,
          project_description: res.data.project.project_description,
          project_si: res.data.project.project_si,
          project_lead: res.data.project.project_lead.u_id,
          project_pmu_sme: res.data.project.project_pmu_sme,
          project_pmu_analyst: res.data.project.project_pmu_analyst,
          project_status: res.data.project.project_status,
          project_rag_status: res.data.project.project_rag_status,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateProjectSubmit = () => {
    const {
      project_title,
      project_unit,
      project_si,
      project_fy,
      project_domain,
      project_spoc,
      project_lead,
      project_start_date,
      project_end_date,
      project_pmu_manager,
      project_pmu_sme,
      project_pmu_analyst,
      project_total_amount,
      project_fund_consumed,
      project_description,
      project_status,
      project_rag_status,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (project_title === "") {
      isValid = false;
      errors.project_title = "Project name is required";
    }

    if (project_unit === "") {
      isValid = false;
      errors.project_unit = "Agency is required";
    }

    if (project_si === "") {
      isValid = false;
      errors.project_si = "SI is required";
    }

    if (project_domain === "") {
      isValid = false;
      errors.project_domain = "Domain is required";
    }

    if (project_spoc === "") {
      isValid = false;
      errors.project_spoc = "SPOC is required";
    }

    if (project_lead === "") {
      isValid = false;
      errors.project_lead = "Lead is required";
    }

    if (project_fy === "") {
      isValid = false;
      errors.project_fy = "Financial year is required";
    }

    if (project_start_date === "") {
      isValid = false;
      errors.project_start_date = "Start date is required";
    }

    if (project_end_date === "") {
      isValid = false;
      errors.project_end_date = "End date is required";
    }

    if (project_pmu_manager === "") {
      isValid = false;
      errors.project_pmu_manager = "PMU Project manager name is required";
    }

    if (project_pmu_sme === "") {
      isValid = false;
      errors.project_pmu_sme = "PMU SME is required";
    }

    if (project_pmu_analyst === "") {
      isValid = false;
      errors.project_pmu_analyst = "PMU analyst is required";
    }

    if (project_total_amount === "") {
      isValid = false;
      errors.project_total_amount = "Original fund allocation is required";
    }

    if (project_fund_consumed === "") {
      isValid = false;
      errors.project_fund_consumed = "Fund consumed is required";
    } else if (project_fund_consumed > project_total_amount) {
      isValid = false;
      errors.project_fund_consumed =
        "Fund consumed should not be greater than original fund allocation";
    }

    if (project_description === "") {
      isValid = false;
      errors.project_description = "Description is required";
    }

    if (project_status === "") {
      isValid = false;
      errors.project_status = "Status is required";
    }

    if (project_rag_status === "") {
      isValid = false;
      errors.project_rag_status = "RAG Status is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleProjectSubmit = (e) => {
    e.preventDefault();
    if (!validateProjectSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_PROJECTS_UPDATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate("/projects");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchDeptOptionsData();
    fetchSiOptionsData();
    fetchFYOptionsData();
    fetchDomainOptionsData();
    fetchManagerOptionsData();
    fetchLeadOptionsData();
  }, []);

  const fetchDeptOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_DEPARTMENT, true, null)
      .then((res) => {
        setDeptOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchSiOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_SI, true, null)
      .then((res) => {
        setSiOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchLeadOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_LEAD, true, null)
      .then((res) => {
        setLeadOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchFYOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_FINANCIAL_YEAR, true, null)
      .then((res) => {
        setFinYearOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDomainOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_DOMAIN, true, null)
      .then((res) => {
        setDomainOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchManagerOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_MANAGER, true, null)
      .then((res) => {
        setManagerOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>Edit Project</h5>
          <div>
            <Button
              className="link-action"
              onClick={() => navigate(`/projects/view/${projectRef}`)}
            >
              View Project
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Form onSubmit={handleProjectSubmit}>
            <Row className="mb-3">
              <Col lg={12}>
                <Form.Group controlId="project_title">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="project_title"
                    placeholder="Enter project name"
                    autoComplete="off"
                    value={formValues.project_title}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  />
                  <small className="error">{formErrors.project_title}</small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_unit">
                  <Form.Label>Project Agency</Form.Label>
                  <Form.Select
                    name="project_unit"
                    value={formValues.project_unit}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select unit</option>
                    {Object.keys(deptOptions).map((key) => (
                      <option key={key} value={key}>
                        {deptOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_unit}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_si">
                  <Form.Label>Project SI</Form.Label>
                  <Form.Select
                    name="project_si"
                    value={formValues.project_si}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select SI</option>
                    {Object.keys(siOptions).map((key) => (
                      <option key={key} value={key}>
                        {siOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_si}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_domain">
                  <Form.Label>Project Domain</Form.Label>
                  <Form.Select
                    name="project_domain"
                    value={formValues.project_domain}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select domain</option>
                    {Object.keys(domainOptions).map((key) => (
                      <option key={key} value={key}>
                        {domainOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_domain}</small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_lead">
                  <Form.Label>Project Lead</Form.Label>
                  <Form.Select
                    name="project_lead"
                    value={formValues.project_lead}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select Project Lead</option>
                    {leadOptions.map((key) => (
                      <option key={key.u_id} value={key.u_id}>
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_lead}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_spoc">
                  <Form.Label>Govt. SPOC</Form.Label>
                  <Form.Select
                    name="project_spoc"
                    value={formValues.project_spoc}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select Govt. SPOC</option>
                    {leadOptions.map((key) => (
                      <option key={key.u_id} value={key.u_id}>
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_spoc}</small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_total_amount">
                  <Form.Label>Original Fund Allocation (Cr.)</Form.Label>
                  <Form.Control
                    type="number"
                    name="project_total_amount"
                    autoComplete="off"
                    value={formValues.project_total_amount}
                    onChange={handleChange}
                    //disabled={roles.includes(user_role) ? false : true}
                    disabled
                    //min="0"
                    min={parseFloat(0.0)}
                  />
                  <small className="error">
                    {formErrors.project_total_amount}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_fund_consumed">
                  <Form.Label>Total Fund Consumed (Cr.)</Form.Label>
                  <Form.Control
                    type="number"
                    name="project_fund_consumed"
                    autoComplete="off"
                    value={formValues.project_fund_consumed}
                    onChange={handleChange}
                    min="0"
                  />
                  <small className="error">
                    {formErrors.project_fund_consumed}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_fy">
                  <Form.Label>Financial Year</Form.Label>
                  <Form.Select
                    name="project_fy"
                    value={formValues.project_fy}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option>Select financial year</option>
                    {Object.keys(finYearOptions).map((key) => (
                      <option key={key} value={finYearOptions[key]}>
                        {finYearOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_fy}</small>
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group controlId="project_start_date">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="project_start_date"
                    autoComplete="off"
                    value={formValues.project_start_date}
                    onChange={handleChange}
                    disabled
                  />
                  <small className="error">
                    {formErrors.project_start_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_end_date">
                  <Form.Label>Planned End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="project_end_date"
                    autoComplete="off"
                    value={formValues.project_end_date}
                    onChange={handleChange}
                    disabled
                  />
                  <small className="error">{formErrors.project_end_date}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_revised_end_date">
                  <Form.Label>Revised End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="project_revised_end_date"
                    autoComplete="off"
                    value={formValues.project_revised_end_date}
                    onChange={handleChange}
                    min={formValues.project_start_date}
                  />
                  <small className="error">
                    {formErrors.project_revised_end_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_actual_end_date">
                  <Form.Label>Actual End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="project_actual_end_date"
                    autoComplete="off"
                    value={formValues.project_actual_end_date}
                    onChange={handleChange}
                    min={formValues.project_start_date}
                  />
                  <small className="error">
                    {formErrors.project_actual_end_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_pmu_manager">
                  <Form.Label>PMU Project Manager</Form.Label>
                  <Form.Select
                    name="project_pmu_manager"
                    value={formValues.project_pmu_manager}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option>Select PMU Project Manager</option>
                    {managerOptions.map((key) => (
                      <option key={key.u_id} value={key.u_id}>
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formErrors.project_pmu_manager}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_pmu_sme">
                  <Form.Label>PMU SME</Form.Label>
                  <Form.Select
                    name="project_pmu_sme"
                    value={formValues.project_pmu_sme}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select PMU SME</option>
                    {managerOptions.map((key) => (
                      <option key={key.u_id} value={key.u_id}>
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_pmu_sme}</small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="project_pmu_analyst">
                  <Form.Label>PMU Analyst</Form.Label>
                  <Form.Select
                    name="project_pmu_analyst"
                    value={formValues.project_pmu_analyst}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  >
                    <option value="">Select PMU Analyst</option>
                    {managerOptions.map((key) => (
                      <option key={key.u_id} value={key.u_id}>
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formErrors.project_pmu_analyst}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_status">
                  <Form.Label>Project Status</Form.Label>
                  <Form.Select
                    name="project_status"
                    value={formValues.project_status}
                    onChange={handleChange}
                  >
                    <option value="">Select status</option>
                    <option value="In Progress">In Progress</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Completed">Completed</option>
                    <option value="Deffered">Deffered</option>
                    <option value="To Be Started">To Be Started</option>
                  </Form.Select>
                  <small className="error">{formErrors.project_status}</small>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="project_rag_status">
                  <Form.Label>RAG Status</Form.Label>
                  <Form.Select
                    name="project_rag_status"
                    value={formValues.project_rag_status}
                    onChange={handleChange}
                    style={{ backgroundColor: formValues.project_rag_status }}
                  >
                    <option value="">Select RAG status</option>
                    {colorOptions.map((color) => (
                      <option
                        key={color.name}
                        value={color.colorCode}
                        style={{
                          backgroundColor: color.colorCode,
                          color: "transparent",
                        }}
                      ></option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formErrors.project_rag_status}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={12}>
                <Form.Group controlId="project_description">
                  <Form.Label>Project Brief Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    cols={100}
                    name="project_description"
                    autoComplete="off"
                    value={formValues.project_description}
                    onChange={handleChange}
                    disabled={roles.includes(user_role) ? false : true}
                  />
                  <small className="error">
                    {formErrors.project_description}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-2 text-end">
              <Button type="submit" className="btn btn-primary mt-2">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
}

export default EditProject;
