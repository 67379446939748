import { useEffect, useState } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  API_LISTS_DEPARTMENT,
  API_FETCH_ROLES_DATA,
  API_ROLE_UPDATE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function EditRole(props) {
  const { roleRef } = props;
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);
  const [formValues, setFormValues] = useState({
    role_name: "",
    is_active: 1,
    role_ref: roleRef,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateRoleSubmit = () => {
    const { role_name } = formValues;
    const errors = {};
    let isValid = true;

    if (role_name === "") {
      isValid = false;
      errors.role_name = "Role name is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setFormValues({ ...formValues, is_active: 1 });
    } else {
      setFormValues({ ...formValues, is_active: 0 });
    }
  };

  const handleRoleSubmit = (e) => {
    e.preventDefault();
    if (!validateRoleSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ROLE_UPDATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/roles/view/${roleRef}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  const fetchRoleData = () => {
    props
      .callRequest("GET", API_FETCH_ROLES_DATA + "/" + roleRef)
      .then((res) => {
        const parsedData = JSON.parse(res.data.roles);
        const roleObj = parsedData[0];
        setApiData(parsedData);
        setFormValues({
          ...formValues,
          role_name: roleObj.r_name,
          is_active: roleObj.is_active,
        });
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>Edit Role</h5>
          <div>
            <Button className="link-action" onClick={() => navigate("/roles")}>
              Back to Roles
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <Form onSubmit={handleRoleSubmit}>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Form.Group controlId="role_name">
                      <Form.Label>Role Name</Form.Label>
                      <Form.Control
                        name="role_name"
                        type="text"
                        value={formValues.role_name}
                        placeholder="Enter Role Name"
                        autoComplete="off"
                        onChange={handleChange}
                      ></Form.Control>
                      <small className="error">{formErrors.role_name}</small>
                    </Form.Group>
                  </Col>
                  
                  <Col lg={2}>
                    <div class="form-check mt-4 pt-3">
                      <input
                        id="flexCheckDefault"
                        name="is_active"
                        class="form-check-input"
                        type="checkbox"
                        checked={formValues.is_active === 1 ? true : false}
                        onChange={handleCheckboxChange}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Is Active
                      </label>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-3 text-end">
                      <Button type="submit" className="btn btn-primary mt-2">
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </Card>
    </>
  );
}

export default EditRole;
