import { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { API_RISKS_VIEW } from "../../config/ApiList";
import {
  showDialog,
  getRiskSeverity,
  getFormatedDateTime,
} from "../../helpers/util";

function ViewRisk(props) {
  const { riskRef } = props;
  const { state } = useLocation();
  const projectRef = state.projectRef;
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    props
      .callRequest("GET", API_RISKS_VIEW + "/" + riskRef, true, null)
      .then((res) => {
        setApiData(res.data.risk);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 3000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>View Risk</h5>
          <div className="d-flex">
            <Button
              className="link-action me-2"
              onClick={() => navigate(`/projects/view/${projectRef}`)}
            >
              Back to Projects
            </Button>
            {user.role === "LEADER" || user.role === "ADMIN" ? null : (
              <Button
                className="link-action"
                onClick={() => navigate(`/risks/edit/${riskRef}`)}
              >
                Edit Risk
              </Button>
            )}
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-9 col-lg-9">
                  <div>
                    <div className="mb-3">
                      <h4 className="fw-bold m-0 me-2">{apiData.risk_name}</h4>
                    </div>

                    <div>
                      <h6 className="fw-bold text-muted">Mitigation Plan</h6>
                      {apiData.risk_mitigation}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <div className="row d-flex align-items-center">
                    <div className="col-6 fw-bold text-muted">Status:</div>
                    <div className="col-6">{apiData.risk_status}</div>
                  </div>

                  <div className="row d-flex align-items-center mt-3">
                    <div className="col-6 fw-bold text-muted">Severity:</div>
                    <div className="col-6">
                      {getRiskSeverity(apiData.risk_factor)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-3 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Identified On</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.risk_identify_date,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-day text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Target Closure On</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.risk_target_date,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {apiData.risk_revise_date ? (
                  <div className="col-xl-3 col-lg-4">
                    <div className="d-flex">
                      <span>
                        <i className="las la-calendar-day text-primary me-2"></i>
                      </span>
                      <div>
                        <h5 className="fw-bold text-muted">
                          Target Revise Date
                        </h5>
                        <span>
                          {getFormatedDateTime(
                            apiData.risk_revise_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {apiData.risk_actual_date ? (
                  <div className="col-xl-3 col-lg-4">
                    <div className="d-flex">
                      <span>
                        <i className="las la-calendar-day text-primary me-2"></i>
                      </span>
                      <div>
                        <h5 className="fw-bold text-muted">
                          Target Resolve Date
                        </h5>
                        <span>
                          {getFormatedDateTime(
                            apiData.risk_actual_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </Card>
    </>
  );
}

export default ViewRisk;
