import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup, Button } from "react-bootstrap";

import {
  API_AUTHENTICATE,
  API_VALIDATE_SECURITY_CODE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function LoginForm(props) {
  const [formValues, setFormValues] = useState({
    user_name: "",
    security_code: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValidate, setIsEmailValidate] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!validateEmailSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_AUTHENTICATE, false, formValues)
      .then((res) => {
        setIsEmailValidate(true);
        setFormValues({ ...formValues, security_code: res.data.security_code });
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 3000);
      });
  };

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (!validateCodeSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_VALIDATE_SECURITY_CODE, false, formValues)
      .then((res) => {
        localStorage.setItem("pmu_token", res.data.token);
        localStorage.setItem("pmu_token_expired_on", res.data.token_expired_on);
        localStorage.setItem("pmu_user", res.data.user);

        navigate("/dashboard");
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  const validateEmailSubmit = () => {
    const { user_name } = formValues;

    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;

    if (user_name === "") {
      isValid = false;
      errors.user_name = "Email address / Phone number is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const validateCodeSubmit = () => {
    const { user_name, security_code } = formValues;

    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;

    if (user_name === "") {
      isValid = false;
      errors.user_name = "Email address / Phone number is required";
    }
    /* else if (!regex.test(values.user_name)) {
      errors.user_name = "Please enter valid email address";
    } */

    if (security_code === "") {
      isValid = false;
      errors.security_code = "Security code is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <>
      {!isEmailValidate ? (
        <Form onSubmit={handleEmailSubmit}>
          <InputGroup className="">
            <InputGroup.Text>
              <i className="las la-envelope"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              name="user_name"
              placeholder="Email address or Phone number"
              autoComplete="off"
              value={formValues.user_name}
              onChange={handleChange}
            />
          </InputGroup>
          <small className="error">{formErrors.user_name}</small>
          <div className="mt-2 text-end">
            <Button type="submit" className="btn btn-primary mt-2">
              Submit
            </Button>
          </div>
        </Form>
      ) : (
        <Form onSubmit={handleCodeSubmit}>
          <InputGroup className="">
            <InputGroup.Text>
              <i className="las la-envelope"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              name="user_name"
              placeholder="Email address"
              autoComplete="off"
              value={formValues.user_name}
              onChange={handleChange}
            />
          </InputGroup>
          <small className="error">{formErrors.user_name}</small>

          <InputGroup className="mt-2">
            <InputGroup.Text>
              <i className="las la-lock"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              name="security_code"
              placeholder="Security code"
              maxLength="6"
              autoComplete="off"
              value={formValues.security_code}
              onChange={handleChange}
            />
          </InputGroup>
          <small className="error">{formErrors.security_code}</small>

          <div className="mt-2 text-end">
            <Button type="submit" className="btn btn-primary mt-2">
              Submit
            </Button>
          </div>
        </Form>
      )}
    </>
  );
}

export default LoginForm;
