import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import {
  API_LISTS_USER,
  API_LISTS_PROJECT,
  API_ACTIVITIES_CREATE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function NewActivityForm(props) {
  const { state } = useLocation();
  const projectId = state !== null && state.projectId ? state.projectId : "";
  const projectRef = state.projectRef;

  const projectStartDate = state.projectStartDate;
  const projectEndDate = state.projectEndDate;
  const projectRevisedEndDate = state.projectRevisedEndDate;

  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  const [formValues, setFormValues] = useState({
    activity_name: "",
    activity_description: "",
    activity_plan_start_date: "",
    activity_plan_end_date: "",
    activity_assigned_to: [],
    project_id: projectId ? projectId : "",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssigneeOptionsData();
    fetchProjectOptionsData();
  }, []);

  const fetchAssigneeOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_USER, true, null)
      .then((res) => {
        setAssigneeOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchProjectOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_PROJECT, true, null)
      .then((res) => {
        setProjectOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value, options } = e.target;
    if (name === "activity_assigned_to") {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setFormValues({ ...formValues, activity_assigned_to: selectedOptions });
    } else if (name === "project_id") {
      setFormValues({ ...formValues, project_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateActivitySubmit = () => {
    const {
      activity_name,
      activity_description,
      activity_plan_start_date,
      activity_plan_end_date,
      activity_assigned_to,
      project_id,
    } = formValues;

    const errors = {};
    let isValid = true;

    if (activity_name === "") {
      isValid = false;
      errors.activity_name = "Activity is required";
    }

    if (activity_assigned_to.length === 0) {
      isValid = false;
      errors.activity_assigned_to = "Assigned to is required";
    }

    if (project_id === "") {
      isValid = false;
      errors.project_id = "Project is required";
    }

    if (activity_plan_start_date === "") {
      isValid = false;
      errors.activity_plan_start_date = "Plan start date is required";
    }

    if (activity_plan_end_date === "") {
      isValid = false;
      errors.activity_plan_end_date = "Plan end date is required";
    }

    if (activity_description === "") {
      isValid = false;
      errors.activity_description = "Description is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleActivitySubmit = (e) => {
    e.preventDefault();
    if (!validateActivitySubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ACTIVITIES_CREATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/projects/view/${projectRef}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>New Activity</h5>
              <div>
                <Button
                  className="link-action"
                  onClick={() => navigate(`/projects/view/${projectRef}`)}
                >
                  Back to Projects
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form onSubmit={handleActivitySubmit}>
        <Row className="mb-3">
          <Col lg={8}>
            <Form.Group controlId="activity_name">
              <Form.Label>Activity</Form.Label>
              <Form.Control
                type="text"
                name="activity_name"
                placeholder="Enter activity detail"
                value={formValues.activity_name}
                autoComplete="off"
                onChange={handleChange}
              />
              <small className="error">{formErrors.activity_name}</small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="activity_assigned_to">
              <Form.Label>Assigned to</Form.Label>
              <Form.Select
                name="activity_assigned_to"
                defaultValue={formValues.activity_assigned_to}
                onChange={handleChange}
                //multiple
              >
                <option value="">Select</option>
                {assigneeOptions.map((key) => (
                  <option key={key.u_id} value={key.u_id}>
                    {key.u_fullname}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.activity_assigned_to}</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="project_id">
              <Form.Label>Project</Form.Label>
              <Form.Select
                name="project_id"
                value={formValues.project_id}
                onChange={handleChange}
                disabled={projectId ? true : false}
              >
                <option value="">Select Project</option>
                {Object.keys(projectOptions).map((key) => (
                  <option key={key} value={key}>
                    {projectOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.project_id}</small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="activity_plan_start_date">
              <Form.Label>Plan Start date</Form.Label>
              <Form.Control
                type="date"
                autoComplete="off"
                name="activity_plan_start_date"
                value={formValues.activity_plan_start_date}
                onChange={handleChange}
                min={projectStartDate}
                max={
                  projectRevisedEndDate ? projectRevisedEndDate : projectEndDate
                }
              />
              <small className="error">
                {formErrors.activity_plan_start_date}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="activity_plan_end_date">
              <Form.Label>Plan End date</Form.Label>
              <Form.Control
                type="date"
                autoComplete="off"
                name="activity_plan_end_date"
                value={formValues.activity_plan_end_date}
                onChange={handleChange}
                min={formValues.activity_plan_start_date}
                max={
                  projectRevisedEndDate ? projectRevisedEndDate : projectEndDate
                }
              />
              <small className="error">
                {formErrors.activity_plan_end_date}
              </small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Group controlId="activity_description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              cols={100}
              placeholder="Leave a comment here"
              name="activity_description"
              value={formValues.activity_description}
              onChange={handleChange}
            />
            <small className="error">{formErrors.activity_description}</small>
          </Form.Group>
        </Row>

        <div className="d-flex justify-content-end">
          <Row>
            <Col lg={4}>
              <Button type="submit" className="btn btn-primary mt-2">
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
}

export default NewActivityForm;
