import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

function Topbar(props) {
    const navigate = useNavigate();
    const { showTopbar } = props;
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
    
        /* if (localStorage.getItem("pmu_token") === null) {
          navigate("/");
        }

        var t = localStorage.getItem("pmu_token_expired_on").split(/[- :]/);
        var dt1 = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        var dt2 = new Date();

        var diff =(dt1.getTime() - dt2.getTime()) / 1000;
        diff /= 60;
        var td = Math.round(diff);
        //console.log(dt1.getTime(), dt2.getTime(), td);
        if(td < 0) {
            localStorage.removeItem('pmu_token');
            localStorage.removeItem('pmu_token_expired_on');
            localStorage.removeItem('pmu_user');
            setUserInfo(null);
            navigate("/");
        }  */

        if (localStorage.getItem("pmu_user") != null) {
          setUserInfo(JSON.parse(localStorage.getItem("pmu_user")));
        } 
    
    }, []);

    const logout = () => {
        localStorage.removeItem("pmu_token");
        localStorage.removeItem("pmu_token_expired_on");
        localStorage.removeItem("pmu_user");
        setUserInfo(null);
        navigate("/");
    };

    return (
      <header className={showTopbar ? "pc-header mob-header-active" : "pc-header"}>
        <div className="header-wrapper">
          <div className="me-auto d-block">
            <h1>Department of Information Technology & Electronics</h1>
            <h2>Government of West Bengal</h2>
          </div>

          <div className="ms-auto">
            <ul className="list-unstyled">
              <li className="dropdown pc-h-item">
                <a
                  className="pc-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo?.profile}
                    alt={userInfo?.fullname}
                    className="user-avtar"
                  />
                  <span>
                    <span className="user-name">
                        {userInfo?.fullname}<br/>
                        <span className="user-desc">{userInfo?.role}</span>
                    </span>
                  </span>
                </a>

                <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                  <Link to={`/profile/${userInfo?.ref_code}`} className="dropdown-item">
                    <i className="las la-user"></i>
                    <span>Profile</span>
                  </Link>
                  
                  <a href="#" onClick={logout} className="dropdown-item">
                    <i className="las la-sign-out-alt"></i>
                    <span>Logout</span>
                  </a>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </header>
    );
}

export default Topbar;
