import { useEffect, useState } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  API_LISTS_ROLE,
  API_FETCH_USER_DATA,
  API_USERS_UPDATE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function EditUser(props) {
  const { userRef } = props;
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    fullname: "",
    email_official: "",
    email_personal: "",
    phone: "",
    phone2: "",
    u_role_id: "",
    user_ref: userRef,
    is_active: 1,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isUserDataSubmit, setIsUserDataSubmit] = useState(false);

  const [apiData, setApiData] = useState(null);

  const [roleOptions, setRoleOptions] = useState([]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    if (name === "u_role_id") {
      setFormValues({ ...formValues, u_role_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setFormValues({ ...formValues, is_active: 1 });
    } else {
      setFormValues({ ...formValues, is_active: 0 });
    }
  };

  const validateUserSubmit = () => {
    const {
      fullname,
      phone,
      email_official,
      email_personal,
      u_role_id,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (fullname === "") {
      isValid = false;
      errors.fullname = "Fullname is required";
    }
    if (phone === "") {
      isValid = false;
      errors.phone = "Phone number is required";
    }
    if (email_official === "") {
      isValid = false;
      errors.email_official = "Official email address is required";
    }
/*     if (email_personal === "") {
      isValid = false;
      errors.email_personal = "Personal email address is required";
    } */
    if (u_role_id === "") {
      isValid = false;
      errors.u_role_id = "Role is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    const hasErrorObj = validateUserSubmit(formValues);
    setFormErrors(hasErrorObj);

    if (Object.keys(hasErrorObj).length === 0 || hasErrorObj === undefined) {
      setIsUserDataSubmit(true);
      props
        .callRequest("POST", API_USERS_UPDATE, true, formValues)
        .then((res) => {
          showDialog("success", res.data.message, 5000);
          navigate("/users");
        })
        .catch((e) => {
          console.log(e);
          showDialog("error", e.data.message, 5000);
        });
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchRoleOptionsData = (params) => {
    props
      .callRequest("GET", API_LISTS_ROLE + "/" + params, true, null)
      .then((res) => {
        setRoleOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  const fetchUserData = () => {
    props
      .callRequest("GET", API_FETCH_USER_DATA + "/" + userRef, true, null)
      .then((res) => {
        const parsedData = JSON.parse(res.data.user);
        setApiData(parsedData);
        fetchRoleOptionsData(parsedData.u_dept_id);
        //console.log(JSON.parse(res.data.user));

        setFormValues({
          ...formValues,
          fullname: parsedData.u_fullname,
          email_official: parsedData.u_email,
          email_personal: parsedData.u_email_personal,
          phone: parsedData.u_phone,
          phone2: parsedData.u_phone2,
          u_role_id: parsedData.u_role_id,
          is_active: parsedData.is_active,
        });
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>Edit User</h5>
          <div>
            <Button className="link-action" onClick={() => navigate("/users")}>
              Back to user
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <Form onSubmit={handleUserSubmit}>
                <Row className="mb-3">
                  <Col lg={4}>
                    <Form.Group controlId="fullname">
                      <Form.Label>Fullname</Form.Label>
                      <Form.Control
                        name="fullname"
                        type="text"
                        value={formValues.fullname}
                        placeholder="Enter Full Name"
                        onChange={handleUserChange}
                      ></Form.Control>
                      <small className="error">{formErrors.fullname}</small>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="number"
                        name="phone"
                        value={formValues.phone}
                        placeholder="Enter Phone Number"
                        onChange={handleUserChange}
                        maxLength={10}
                      />
                      <small className="error">{formErrors.phone}</small>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group controlId="phone2">
                      <Form.Label>Alternate Phone</Form.Label>
                      <Form.Control
                        type="number"
                        name="phone2"
                        value={formValues.phone2}
                        placeholder="Enter Phone Number"
                        onChange={handleUserChange}
                        maxLength={10}
                      />
                      <small className="error">{formErrors.phone2}</small>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={4}>
                    <Form.Group controlId="email_official">
                      <Form.Label>Official Email ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="email_official"
                        value={formValues.email_official}
                        placeholder="Enter Official Email ID"
                        onChange={handleUserChange}
                      />
                      <small className="error">
                        {formErrors.email_official}
                      </small>
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Group controlId="email_personal">
                      <Form.Label>Personal Email ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="email_personal"
                        value={formValues.email_personal}
                        placeholder="Enter Personal Email ID"
                        onChange={handleUserChange}
                      />
                      <small className="error">
                        {formErrors.email_personal}
                      </small>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  
                  <Col lg={4}>
                    <Form.Group controlId="u_role_id">
                      <Form.Label>Role</Form.Label>
                      <select
                        name="u_role_id"
                        value={formValues.u_role_id}
                        onChange={handleUserChange}
                        className="form-select"
                      >
                        <option value="">Select role</option>
                        {Object.keys(roleOptions).map((key) => (
                          <option key={key} value={key}>
                            {roleOptions[key]}
                          </option>
                        ))}
                      </select>
                      <small className="error">{formErrors.u_role_id}</small>
                    </Form.Group>
                  </Col>

                  <Col lg={4}>
                    <Form.Label> &nbsp; </Form.Label>
                    <div className="form-check mt-2">
                      <input
                        id="flexCheckDefault"
                        name="is_active"
                        className="form-check-input"
                        type="checkbox"
                        checked={formValues.is_active === 1 ? true : false}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Is Active
                      </label>
                    </div>
                  </Col>
                </Row>

                {/* <input type="hidden" name="user_ref" value={formValues.user_ref} /> */}

                <div className="mt-2 text-end">
                  <Button type="submit" className="btn btn-primary mt-2">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </Card>
    </>
  );
}

export default EditUser;
