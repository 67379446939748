import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { API_ISSUES } from "../../../config/ApiList";
import {
  showDialog,
  getFormatedDateTime,
  getExcerpt,
} from "../../../helpers/util";

function ProjectIssue(props) {
  const { projectRef } = props;

  const [loadIssues, setLoadIssues] = useState(false);
  const [issueData, setIssueData] = useState([]);
  const [issueCount, setIssueCount] = useState(0);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = () => {
    props
      .callRequest("GET", API_ISSUES + "/" + props.projectRef, true, null)
      .then((res) => {
        setLoadIssues(true);
        setIssueCount(res.data.count);
        setIssueData(res.data.issues);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Issue</th>
            <th>Owner</th>
            <th>Raised On</th>
            <th>Deadline</th>
            <th>Status</th>
            <th>Priority</th>
            {user.role === "LEADER" || user.role === "ADMIN" ? null : (
              <th>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {issueCount > 0 ? (
            issueData.map((issue, i) => {
              return (
                <tr key={i}>
                  <td>
                    <span className="fs-5 fw-bold">
                      <Link
                        to={`/issues/view/${issue.issue_ref}`}
                        state={{ projectRef: projectRef }}
                      >
                        {getExcerpt(issue.issue_name, 30)}
                      </Link>
                    </span>
                  </td>
                  <td data-toggle="tooltip" title={`${issue.user.u_fullname}`}>
                    {issue.user.u_fullname}
                  </td>
                  <td>
                    {getFormatedDateTime(
                      issue.issue_detect_date,
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>
                    {issue.issue_resolve_date
                      ? getFormatedDateTime(
                          issue.issue_resolve_date,
                          "DD-MMM-YYYY"
                        )
                      : issue.issue_revise_date
                      ? getFormatedDateTime(
                          issue.issue_revise_date,
                          "DD-MMM-YYYY"
                        )
                      : getFormatedDateTime(
                          issue.issue_target_date,
                          "DD-MMM-YYYY"
                        )}
                  </td>
                  {/* <td>{getIssuesSeverity(issue.issue_status)}</td> */}
                  <td>{issue.issue_status}</td>
                  <td>{issue.issue_priority}</td>
                  {user.role === "LEADER" || user.role === "ADMIN" ? null : (
                    <td className="text-left">
                      <Link
                        to={`/issues/Edit/${issue.issue_ref}`}
                        state={{ projectRef: projectRef }}
                      >
                        <i className="las la-edit"></i>
                      </Link>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No issue found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ProjectIssue;
