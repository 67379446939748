import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { API_LISTS_DEPARTMENT, API_ROLE_CREATE } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function NewRoleForm(props) {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    role_name: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateRoleSubmit = () => {
    const { role_name, dept_id } = formValues;
    const errors = {};
    let isValid = true;

    if (role_name === "") {
      isValid = false;
      errors.role_name = "Role name is required";
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleRoleSubmit = (e) => {
    e.preventDefault();
    if (!validateRoleSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ROLE_CREATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate("/roles");
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <Form onSubmit={handleRoleSubmit}>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="role_name">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                name="role_name"
                type="text"
                value={formValues.role_name}
                placeholder="Enter Role Name"
                autoComplete="off"
                onChange={handleChange}
              ></Form.Control>
              <small className="error">{formErrors.role_name}</small>
            </Form.Group>
          </Col>
          
          <Col>
            <div className="mt-3 text-end">
              <Button type="submit" className="btn btn-primary mt-2">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default NewRoleForm;
