import { useState, useEffect } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showDialog } from "../../helpers/util";
import { useSelector } from "react-redux";

import {
  API_LISTS_USER,
  API_LISTS_PROJECT,
  API_FETCH_ACTIVITY_DATA,
  API_ACTIVITIES_UPDATE,
} from "../../config/ApiList";

function EditActivity(props) {
  const { activityRef } = props;
  const navigate = useNavigate();
  const { project } = useSelector((state) => state.project);

  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);

  const [formValues, setFormValues] = useState({
    activity_name: "",
    activity_description: "",
    activity_plan_start_date: "",
    activity_plan_end_date: "",
    activity_actual_start_date: "",
    activity_actual_end_date: "",
    activity_revised_end_date: "",
    activity_assigned_to: [],
    activity_status: "",
    project_id: "",
    activity_ref: activityRef,
  });
  const [formErrors, setFormErrors] = useState({});

  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    fetchAssigneeOptionsData();
    fetchProjectOptionsData();
    fetchActivityData();
  }, []);

  const fetchAssigneeOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_USER, true, null)
      .then((res) => {
        setAssigneeOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchProjectOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_PROJECT, true, null)
      .then((res) => {
        setProjectOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchActivityData = () => {
    props
      .callRequest(
        "GET",
        API_FETCH_ACTIVITY_DATA + "/" + activityRef,
        true,
        null
      )
      .then((res) => {
        setApiData(res.data.activity);

        let assignTo = [];

        res.data.activity.assign.map((obj) => {
          assignTo.push(obj.user.u_id);
        });
        setFormValues({
          ...formValues,
          activity_name: res.data.activity.activity_name,
          activity_description: res.data.activity.activity_description,
          activity_plan_start_date: res.data.activity.activity_plan_start_date,
          activity_plan_end_date: res.data.activity.activity_plan_end_date,
          activity_revised_end_date: res.data.activity.activity_revised_end_date,
          activity_actual_start_date:
            res.data.activity.activity_actual_start_date,
          activity_actual_end_date: res.data.activity.activity_actual_end_date,
          //activity_assigned_to: assignTo,
          activity_assigned_to: [`${res.data.activity.assign[0].user.u_id}`],
          activity_status: res.data.activity.activity_status,
          project_id: res.data.activity.project.project_id,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { name, value, options } = e.target;
    if (name === "activity_assigned_to") {
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setFormValues({ ...formValues, activity_assigned_to: selectedOptions });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };

  const validateActivitySubmit = () => {
    const {
      activity_name,
      activity_description,
      activity_plan_start_date,
      activity_plan_end_date,
      activity_revised_end_date,
      activity_actual_start_date,
      activity_actual_end_date,
      activity_assigned_to,
      activity_status,
      project_id,
    } = formValues;

    const errors = {};
    let isValid = true;

    if (activity_name === "") {
      isValid = false;
      errors.activity_name = "Activity is required";
    }

    if (activity_assigned_to.length === 0) {
      isValid = false;
      errors.activity_assigned_to = "Assigned to is required";
    }

    if (project_id === "") {
      isValid = false;
      errors.project_id = "Project is required";
    }

    if (activity_plan_start_date === "") {
      isValid = false;
      errors.activity_plan_start_date = "Plan start date is required";
    }

    if (activity_plan_end_date === "") {
      isValid = false;
      errors.activity_plan_end_date = "Plan end date is required";
    }

    if (
      activity_revised_end_date !== null &&
      activity_revised_end_date < activity_plan_start_date
    ) {
      isValid = false;
      errors.activity_revised_end_date =
        "Revised end date can not be less than start date";
    }

    if (activity_actual_start_date === "") {
      isValid = false;
      errors.activity_actual_start_date = "Actual start date is required";
    } else if (activity_actual_start_date < activity_plan_start_date) {
      isValid = false;
      errors.activity_actual_start_date =
        "Actual start date can not be less than start date";
    }

    if (activity_actual_end_date === "") {
      isValid = false;
      errors.activity_actual_end_date = "Actual end date is required";
    } else if (activity_actual_end_date < activity_plan_start_date) {
      isValid = false;
      errors.activity_actual_end_date =
        "Actual end date can not be less than start date";
    }

    if (activity_description === "") {
      isValid = false;
      errors.activity_description = "Description is required";
    }

    if (activity_status === "") {
      isValid = false;
      errors.activity_status = "Status is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleActivitySubmit = (e) => {
    e.preventDefault();
    if (!validateActivitySubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ACTIVITIES_UPDATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/projects/view/${project.project_ref}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>Edit Activity</h5>
          <div>
            <Button
              className="link-action"
              onClick={() => navigate(`/projects/view/${project.project_ref}`)}
            >
              Back to Projects
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Form onSubmit={handleActivitySubmit}>
            <Row className="mb-3">
              <Col lg={8}>
                <Form.Group controlId="activity_name">
                  <Form.Label>Activity</Form.Label>
                  <Form.Control
                    type="text"
                    name="activity_name"
                    placeholder="Enter activity detail"
                    value={formValues.activity_name}
                    autoComplete="off"
                    onChange={handleChange}
                  />
                  <small className="error">{formErrors.activity_name}</small>
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group controlId="activity_assigned_to">
                  <Form.Label>Assigned to</Form.Label>
                  <Form.Select
                    name="activity_assigned_to"
                    value={formValues.activity_assigned_to}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {assigneeOptions.map((key) => (
                      <option
                        key={key.u_id}
                        value={key.u_id}
                        selected={Object.values(
                          formValues?.activity_assigned_to[0] || {}
                        ).includes(key.u_fullname)}
                      >
                        {key.u_fullname}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">
                    {formErrors.activity_assigned_to}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="project_id">
                  <Form.Label>Project</Form.Label>
                  <Form.Select
                    name="project_id"
                    value={formValues.project_id}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="">Select Project</option>
                    {Object.keys(projectOptions).map((key) => (
                      <option key={key} value={key}>
                        {projectOptions[key]}
                      </option>
                    ))}
                  </Form.Select>
                  <small className="error">{formErrors.project_id}</small>
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Group controlId="activity_plan_start_date">
                  <Form.Label>Start date</Form.Label>
                  <Form.Control
                    type="date"
                    autoComplete="off"
                    name="activity_plan_start_date"
                    value={formValues.activity_plan_start_date}
                    onChange={handleChange}
                    disabled
                  />
                  <small className="error">
                    {formErrors.activity_plan_start_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="activity_plan_end_date">
                  <Form.Label>Planned End date</Form.Label>
                  <Form.Control
                    type="date"
                    autoComplete="off"
                    name="activity_plan_end_date"
                    value={formValues.activity_plan_end_date}
                    onChange={handleChange}
                    disabled
                  />
                  <small className="error">
                    {formErrors.activity_plan_end_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <Form.Group controlId="activity_revised_end_date">
                  <Form.Label>Revised End date</Form.Label>
                  <Form.Control
                    type="date"
                    autoComplete="off"
                    name="activity_revised_end_date"
                    value={formValues.activity_revised_end_date}
                    onChange={handleChange}
                    min={formValues.activity_plan_start_date}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.activity_revised_end_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="activity_actual_start_date">
                  <Form.Label>Actual Start date</Form.Label>
                  <Form.Control
                    type="date"
                    autoComplete="off"
                    name="activity_actual_start_date"
                    value={formValues.activity_actual_start_date}
                    onChange={handleChange}
                    min={formValues.activity_plan_start_date}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.activity_actual_start_date}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="activity_actual_end_date">
                  <Form.Label>Actual End date</Form.Label>
                  <Form.Control
                    type="date"
                    autoComplete="off"
                    name="activity_actual_end_date"
                    value={formValues.activity_actual_end_date}
                    onChange={handleChange}
                    min={formValues.activity_actual_start_date}
                    max={
                      project.project_revised_end_date
                        ? project.project_revised_end_date
                        : project.project_end_date
                    }
                  />
                  <small className="error">
                    {formErrors.activity_actual_end_date}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={9}>
                <Form.Group controlId="activity_description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    cols={100}
                    placeholder="Leave a comment here"
                    name="activity_description"
                    value={formValues.activity_description}
                    onChange={handleChange}
                  />
                  <small className="error">
                    {formErrors.activity_description}
                  </small>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group controlId="activity_status">
                  <Form.Label>Activity Status</Form.Label>
                  <Form.Select
                    name="activity_status"
                    value={formValues.activity_status}
                    onChange={handleChange}
                  >
                    <option value="">Select status</option>
                    <option value="New">New</option>
                    <option value="In Progress">In Progress</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Completed">Completed</option>
                    <option value="Deffered">Deffered</option>
                    <option value="To Be Started">To Be Started</option>
                  </Form.Select>
                  <small className="error">{formErrors.activity_status}</small>
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Row>
                <Col lg={4}>
                  <Button type="submit" className="btn btn-primary mt-2">
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
}

export default EditActivity;
