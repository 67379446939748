import { useState, useEffect, useRef } from "react";
import { Button, Card, Badge, ProgressBar, Tabs, Tab } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import { API_PROJECTS_VIEW } from "../../config/ApiList";
import ProjectActivity from "../../components/project/tabs/ProjectActivity";
import ProjectIssue from "../../components/project/tabs/ProjectIssue";
import ProjectRisk from "../../components/project/tabs/ProjectRisk";
import ProjectAction from "./tabs/ProjectAction";
import { getFormatedDateTime } from "../../helpers/util";
import { useDispatch } from "react-redux";
import { setProjectDetails } from "../../features/project/projectSlice";

function ViewProject(props) {
  const { projectRef } = props;
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [projectId, setProjectId] = useState("");
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    props
      .callRequest("GET", API_PROJECTS_VIEW + "/" + projectRef, true, null)
      .then((res) => {
        setApiData(res.data.project);
        setProjectId(res.data.project.project_id);
        dispatch(setProjectDetails(res.data.project));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>View Project</h5>
          {user.role === "SUPERADMIN" ||
          user.role === "ADMIN" ||
          user.role === "DEPARTMENT PROJECT MEMBER" ? (
            <Button
              className="link-action"
              onClick={() => navigate(`/projects/edit/${projectRef}`)}
            >
              Edit Project
            </Button>
          ) : null}
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-9 col-lg-9">
                  <div className="d-flex align-items-center">
                    <h4 className="fw-bold m-0 me-2">
                      {apiData.project_title}
                    </h4>
                  </div>
                  <Badge bg="secondary">{apiData.project_status}</Badge>
                </div>
                <div className="col-xl-3 col-lg-3 info">
                  {/* <h5 className="fw-bold">
                    <span className="text-muted">Progress</span>&nbsp;
                    <span>{apiData.project_completion}%</span>
                  </h5>
                  <ProgressBar
                    animated
                    variant="info"
                    now={apiData.project_completion}
                  /> */}
                  <div
                    className="rag-status ms-auto"
                    style={{ backgroundColor: `${apiData.project_rag_status}` }}
                  ></div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-landmark text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Project SIA & SLNA</h5>
                      <span>{apiData.project_department.d_name}</span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-building text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Project Agency</h5>
                      <span>{apiData.project_agency.da_name}</span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-satellite-dish text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Domain</h5>
                      <span>{apiData.project_domain?.pd_name}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-user-tie text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Govt. SPOC</h5>
                      <Link
                        to={`/users/view/${apiData.project_spoc.u_ref}`}
                        data-toggle="tooltip"
                        title={`${apiData.project_spoc.u_fullname}`}
                      >
                        {apiData.project_spoc.u_fullname}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-user-alt text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Project Lead</h5>
                      <Link
                        to={`/users/view/${apiData.project_lead.u_ref}`}
                        data-toggle="tooltip"
                        title={`${apiData.project_lead.u_fullname}`}
                      >
                        {apiData.project_lead.u_fullname}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-rupee-sign text-primary me-2"></i>
                    </span>
                    <div>
                      <div className="fw-bold text-muted">
                        CAF Utilised (Cr.)
                      </div>
                      <span>{apiData.project_fund_consumed}</span> of{" "}
                      <span>{apiData.project_total_amount}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-user text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">
                        PMU Project Manager
                      </h5>
                      <Link
                        to={`/users/view/${apiData.project_manager.u_ref}`}
                        data-toggle="tooltip"
                        title={`${apiData.project_manager.u_fullname}`}
                      >
                        {apiData.project_manager.u_fullname}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-user text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">PMU SME</h5>
                      <Link
                        to={`/users/view/${apiData.project_sme.u_ref}`}
                        data-toggle="tooltip"
                        title={`${apiData.project_sme.u_fullname}`}
                      >
                        {apiData.project_sme.u_fullname}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-user text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">PMU Analyst</h5>
                      <Link
                        to={`/users/view/${apiData.project_analyst.u_ref}`}
                        data-toggle="tooltip"
                        title={`${apiData.project_analyst.u_fullname}`}
                      >
                        {apiData.project_analyst.u_fullname}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-hourglass-half text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Financial Year</h5>
                      <span>{apiData.project_fy}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Project Start</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.project_start_date,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-day text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Project Deadline</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.project_end_date,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-day text-primary me-2"></i>
                    </span>

                    <div>
                      <h5 className="fw-bold text-muted">Revised End Date</h5>
                      {apiData.project_revised_end_date ? (
                        <span>
                          {getFormatedDateTime(
                            apiData.project_revised_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      ) : (
                        "N/A"
                      )}
                      {/* {apiData.project_revised_end_date ? (
                        <span>
                          {getFormatedDateTime(
                            apiData.project_revised_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      ) : (
                        <span>
                          {getFormatedDateTime(
                            apiData.project_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar-day text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Actual End Date</h5>
                      {apiData.project_actual_end_date ? (
                        <span>
                          {getFormatedDateTime(
                            apiData.project_actual_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      ) : (
                        "N/A"
                      )}
                      {/* {apiData.project_actual_end_date ? (
                        <span>
                          {getFormatedDateTime(
                            apiData.project_actual_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      ) : (
                        <span>
                          {getFormatedDateTime(
                            apiData.project_end_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-12 col-lg-12">
                  <div>{apiData.project_description}</div>
                </div>
              </div>

              <Tabs
                defaultActiveKey="activity"
                id="fill-tab"
                className="tabs-border mt-3"
                fill
              >
                <Tab eventKey="activity" title="Activity">
                  {user.role === "SUPERADMIN" ||
                  user.role === "DEPARTMENT PROJECT MEMBER" ? (
                    <div className="d-flex justify-content-end mb-3">
                      <Link
                        to="/activities/new"
                        state={{
                          projectId: projectId,
                          projectRef: projectRef,
                          projectStartDate: apiData.project_start_date,
                          projectEndDate: apiData.project_end_date,
                          projectRevisedEndDate:
                            apiData.project_revised_end_date,
                        }}
                        className="link-action btn btn-primary btn-sm mt-3"
                      >
                        Add Activity
                      </Link>
                    </div>
                  ) : (
                    <>
                      <br />
                    </>
                  )}
                  {console.log(apiData.project_end_date, "end_date")}
                  <ProjectActivity projectRef={projectRef} {...props} />
                </Tab>

                <Tab eventKey="issue" title="Issue">
                  {user.role === "SUPERADMIN" ||
                  user.role === "DEPARTMENT PROJECT MEMBER" ||
                  user.role === "PMU PROJECT MEMBER" ? (
                    <div className="d-flex justify-content-end mb-3">
                      <Link
                        to="/issues/new"
                        state={{
                          projectId: projectId,
                          projectRef: projectRef,
                          projectStartDate: apiData.project_start_date,
                          projectEndDate: apiData.project_end_date,
                          projectRevisedEndDate:
                            apiData.project_revised_end_date,
                        }}
                        className="link-action btn btn-primary btn-sm mt-3"
                      >
                        Add Issue
                      </Link>
                    </div>
                  ) : (
                    <>
                      <br />
                    </>
                  )}

                  <ProjectIssue projectRef={projectRef} {...props} />
                </Tab>

                <Tab eventKey="risk" title="Risk">
                  {user.role === "SUPERADMIN" ||
                  user.role === "DEPARTMENT PROJECT MEMBER" ||
                  user.role === "PMU PROJECT MEMBER" ? (
                    <div className="d-flex justify-content-end mb-3">
                      <Link
                        to="/risks/new"
                        state={{
                          projectId: projectId,
                          projectRef: projectRef,
                          projectStartDate: apiData.project_start_date,
                          projectEndDate: apiData.project_end_date,
                          projectRevisedEndDate:
                            apiData.project_revised_end_date,
                        }}
                        className="link-action btn btn-primary btn-sm mt-3"
                      >
                        Add Risk
                      </Link>
                    </div>
                  ) : (
                    <>
                      <br />
                    </>
                  )}

                  <ProjectRisk projectRef={projectRef} {...props} />
                </Tab>

                <Tab eventKey="actions" title="Actions">
                  {user.role === "SUPERADMIN" ||
                  user.role === "DEPARTMENT PROJECT MEMBER" ||
                  user.role === "PMU PROJECT MEMBER" ? (
                    <div className="d-flex justify-content-end mb-3">
                      <Link
                        to="/actions/new"
                        state={{
                          projectRef: projectRef,
                          projectStartDate: apiData.project_start_date,
                          projectEndDate: apiData.project_end_date,
                          projectRevisedEndDate:
                            apiData.project_revised_end_date,
                        }}
                        className="link-action btn btn-primary btn-sm mt-3"
                      >
                        Add Action
                      </Link>
                    </div>
                  ) : (
                    <>
                      <br />
                    </>
                  )}

                  <ProjectAction projectRef={projectRef} {...props} />
                </Tab>
              </Tabs>

              <div className="row project-description border-bottom py-3">
                <small className="d-flex align-items-center my-2">
                  <div className="me-2">
                    Created by: {apiData.project_creator.u_fullname},
                    {apiData.project_creator.u_designation}
                  </div>
                  <div className="me-2">
                    Created on: {apiData.project_created_on}
                  </div>
                </small>
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </Card>
    </>
  );
}

export default ViewProject;
