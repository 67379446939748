import { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { API_ACTION_VIEW } from "../../config/ApiList";
import { showDialog, getFormatedDateTime } from "../../helpers/util";

function ViewAction(props) {
  const { actionRef } = props;
  const { state } = useLocation();
  const projectRef = state.projectRef;
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    props
      .callRequest("GET", API_ACTION_VIEW + "/" + actionRef, true, null)
      .then((res) => {
        setApiData(res.data.action);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 3000);
      });
  };

  return (
    <>
      <Card className="proj-progress-card">
        <div className="card-header">
          <h5>View Action</h5>
          <div className="d-flex">
            <Button
              className="link-action me-2"
              onClick={() => navigate(`/projects/view/${projectRef}`)}
            >
              Back to Projects
            </Button>
            <Button
              className="link-action"
              onClick={() => navigate(`/actions/edit/${actionRef}`)}
            >
              Edit Action
            </Button>
          </div>
        </div>
        <div className="card-body">
          {apiData ? (
            <div className="project">
              <div className="row border-bottom pb-3">
                <div className="col-xl-9 col-lg-9">
                  <div>
                    <div className="mb-3">
                      <h4 className="fw-bold m-0 me-2">
                        {apiData.action_description}
                      </h4>
                    </div>

                    <div>
                      <span>
                        <i className="las la-user text-primary me-2"></i>
                        {apiData.user.u_fullname}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row border-bottom py-3">
                <div className="col-xl-4 col-lg-4">
                  <div className="d-flex">
                    <span>
                      <i className="las la-calendar text-primary me-2"></i>
                    </span>
                    <div>
                      <h5 className="fw-bold text-muted">Deadline</h5>
                      <span>
                        {getFormatedDateTime(
                          apiData.action_deadline,
                          "DD-MMM-YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {apiData.action_revised_date ? (
                  <div className="col-xl-4 col-lg-4">
                    <div className="d-flex">
                      <span>
                        <i className="las la-calendar text-primary me-2"></i>
                      </span>
                      <div>
                        <h5 className="fw-bold text-muted">Revised Date</h5>
                        <span>
                          {getFormatedDateTime(
                            apiData.action_revised_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {apiData.action_actual_date ? (
                  <div className="col-xl-4 col-lg-4">
                    <div className="d-flex">
                      <span>
                        <i className="las la-calendar text-primary me-2"></i>
                      </span>
                      <div>
                        <h5 className="fw-bold text-muted">Actual Date</h5>
                        <span>
                          {getFormatedDateTime(
                            apiData.action_actual_date,
                            "DD-MMM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </div>
      </Card>
    </>
  );
}

export default ViewAction;
