import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { API_UPCOMING_ACTIVITIES } from "../../config/ApiList";
import { showDialog, getFormatedDateTime } from "../../helpers/util";

function UpcomingActivity(props) {
  const [activityData, setActivityData] = useState([]);
  const [activityCount, setActivityCount] = useState(0);

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = () => {
    props
      .callRequest("GET", API_UPCOMING_ACTIVITIES, true, null)
      .then((res) => {
        setActivityCount(res.data.count);
        setActivityData(res.data.activities);
      })
      .catch((e) => {
        showDialog("error", e.response.data.message, 2000);
      });
  };

  return (
    <>
      <Card className="h360">
        <Card.Body>
          <h4 className="mb-4 text-muted fw-bold">Upcoming Activities</h4>
          {activityCount > 0 ? (
            activityData.map((activity, i) => {
              return (
                <div className="row mb-3" key={i}>
                  <div className="col-md-12">
                    <h6 className="m-0 fw-bold">
                      <Link to={`/activities/view/${activity.activity_ref}`}>
                        {activity.activity_name}
                      </Link>
                    </h6>
                    <small className="">
                      <span className="text-muted me-3">Deadline:</span>
                      {getFormatedDateTime(activity.activity_plan_end_date)}
                    </small>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="row">
              <div className="col-12">
                <p>No activity found</p>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default UpcomingActivity;
