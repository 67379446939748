import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { API_ACTIONS } from "../../../config/ApiList";
import {
  showDialog,
  getExcerpt,
  getFormatedDateTime,
} from "../../../helpers/util";

function ProjectAction(props) {
  const { projectRef } = props;
  const [loadActions, setLoadActions] = useState(false);
  const [actionData, setActionData] = useState([]);
  const [actionCount, setActionCount] = useState(0);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchActions();
  }, []);

  const fetchActions = () => {
    props
      .callRequest("GET", API_ACTIONS + "/" + props.projectRef, true, null)
      .then((res) => {
        setLoadActions(true);
        setActionCount(res.data.count);
        setActionData(res.data.actions);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Description</th>
            <th>Owner</th>
            <th>Deadline</th>
            {user.role === "LEADER" || user.role === "ADMIN" ? null : (
              <th>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {actionCount > 0 ? (
            actionData.map((action, i) => {
              return (
                <tr key={i}>
                  <td>
                    <span className="fs-5 fw-bold">
                      <Link
                        to={`/actions/view/${action.action_ref}`}
                        state={{ projectRef: projectRef }}
                      >
                        {getExcerpt(action.action_description, 30)}
                      </Link>
                    </span>
                  </td>
                  <td data-toggle="tooltip" title={`${action.user.u_fullname}`}>
                    {action.user.u_fullname}
                  </td>
                  <td>
                    {action.action_actual_date
                      ? getFormatedDateTime(
                          action.action_actual_date,
                          "DD-MMM-YYYY"
                        )
                      : action.action_revised_date
                      ? getFormatedDateTime(
                          action.action_revised_date,
                          "DD-MMM-YYYY"
                        )
                      : getFormatedDateTime(
                          action.action_deadline,
                          "DD-MMM-YYYY"
                        )}
                  </td>
                  {user.role === "LEADER" || user.role === "ADMIN" ? null : (
                    <td className="text-left">
                      <Link to={`/actions/edit/${action.action_ref}`}>
                        <i className="las la-edit"></i>
                      </Link>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No action found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ProjectAction;
