import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { API_ROLES, API_USERS } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function ListRole(props) {
  const [loadRoleData, setLoadRoleData] = useState(false);
  const [roleData, setRoleData] = useState([]);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchRoleListData();
  }, []);

  const fetchRoleListData = () => {
    props
      .callRequest("GET", API_ROLES, true, null)
      .then((res) => {
        setLoadRoleData(true);
        setRoleData(res.data.roles);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>Available Roles</h5>
        </div>
        <div className="card-body">
          {loadRoleData ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Role Name</th>
                  <th className="text-center">Permissions</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {roleData.map((role, i) => {
                  return (
                    <tr key={i}>
                      
                      <td>
                        <span className="fs-5 fw-bold">
                          <Link to={`/roles/view/${role.r_ref}`}>
                            {role.r_name}
                          </Link>
                        </span>
                      </td>
                      <td className="text-center">
                        <Link
                          to="/roles/permissions"
                          state={{ roleId: role.r_id }}
                        >
                          <i className="las la-lock"></i>
                        </Link>
                      </td>

                      <td className="text-center">
                        <Link to={`/roles/edit/${role.r_ref}`}>
                          <i className="las la-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </>
  );
}

export default ListRole;
