import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import { API_USERS } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function ListUser(props) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [loadUsers, setLoadUsers] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    props
      .callRequest("GET", API_USERS, true, null)
      .then((res) => {
        setLoadUsers(true);
        setUserCount(res.data.count);
        setUserData(res.data.users);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>Available Users</h5>
          <div>
            <Button
              className="link-action"
              onClick={() => navigate("/users/new")}
            >
              New User
            </Button>
          </div>
        </div>
        <div className="card-body">
          {loadUsers ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Fullname</th>
                  <th>
                    Phone Number &<br />
                    Email ID
                  </th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userCount > 0
                  ? userData.map((user, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <span className="fs-6 fw-bold">
                              <Link to={`/users/view/${user.u_ref}`}>
                                {user.u_fullname}
                              </Link>
                            </span>
                          </td>
                          <td>
                            <span>{user.u_phone}</span> <br />
                            <span>{user.u_email}</span>
                          </td>
                          <td>
                            <span>{user.role.r_name}</span>
                          </td>
                          <td className="text-center">
                            <Link to={`/users/edit/${user.u_ref}`}>
                              <i className="las la-edit"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  : "No user found"}
              </tbody>
            </Table>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </>
  );
}

export default ListUser;
