import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showDialog } from "../../helpers/util";
import { useSelector } from "react-redux";

import {
  API_FETCH_ACTION_DATA,
  API_LISTS_USER,
  API_ACTIONS_UPDATE,
} from "../../config/ApiList";

function EditAction(props) {
  const { actionRef } = props;
  const navigate = useNavigate();

  const { project } = useSelector((state) => state.project);

  const [formValues, setFormValues] = useState({
    action_owner: "",
    action_deadline: "",
    action_revised_deadline: "",
    action_actual_deadline: "",
    action_description: "",
    action_status: "",
    action_ref: actionRef,
  });

  const [formErrors, setFormErrors] = useState({});
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [apiData, setApiData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateActionSubmit = () => {
    const {
      action_owner,
      action_deadline,
      action_description,
      action_status,
      // action_revised_deadline,
      // action_actual_deadline,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (action_owner === "") {
      isValid = false;
      errors.action_owner = "Owner is required";
    }

    if (action_deadline === "") {
      isValid = false;
      errors.action_deadline = "Deadline is required";
    }

    // if (action_revised_deadline === "") {
    //   isValid = false;
    //   errors.action_revised_deadline = "Revised deadline is required";
    // }

    // if (action_actual_deadline === "") {
    //   isValid = false;
    //   errors.action_actual_deadline = "Actual deadline is required";
    // }

    if (action_description === "") {
      isValid = false;
      errors.action_description = "Action description is required";
    }

    if (action_status === "") {
      isValid = false;
      errors.action_status = "Action status is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  useEffect(() => {
    fetchOwnerOptionsData();
    fetchActionData();
  }, []);

  const fetchOwnerOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_USER, true, null)
      .then((res) => {
        setOwnerOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchActionData = () => {
    props
      .callRequest("GET", API_FETCH_ACTION_DATA + "/" + actionRef, true, null)
      .then((res) => {
        setApiData(res.data.action);
        setFormValues({
          ...formValues,
          action_owner: res.data.action.action_owner,
          action_deadline: res.data.action.action_deadline,
          action_description: res.data.action.action_description,
          action_status: res.data.action.action_status,
          action_revised_deadline: res.data.action.action_revised_date,
          action_actual_deadline: res.data.action.action_actual_date,
        });
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 3000);
      });
  };

  const handleActionSubmit = (e) => {
    e.preventDefault();
    if (!validateActionSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ACTIONS_UPDATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/projects/view/${project.project_ref}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Edit Action</h5>
              <div>
                <Button
                  className="link-action"
                  onClick={() =>
                    navigate(`/projects/view/${project.project_ref}`)
                  }
                >
                  Back to Projects
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form onSubmit={handleActionSubmit}>
        <Row className="mb-3">
          <Col lg={12}>
            <Form.Group controlId="action_description">
              <Form.Label>Action Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="action_description"
                placeholder="Enter Action description"
                autoComplete="off"
                value={formValues.action_description}
                onChange={handleChange}
              />
              <small className="error">{formErrors.action_description}</small>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="action_owner">
              <Form.Label>Owner</Form.Label>
              <Form.Select
                name="action_owner"
                autoComplete="off"
                value={formValues.action_owner}
                onChange={handleChange}
              >
                <option value="">Select Owner</option>
                {ownerOptions.map((key) => (
                  <option key={key.u_id} value={key.u_id}>
                    {key.u_fullname}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.action_owner}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="action_deadline">
              <Form.Label>Planned Deadline</Form.Label>
              <Form.Control
                type="date"
                name="action_deadline"
                autoComplete="off"
                value={formValues.action_deadline}
                onChange={handleChange}
                disabled
              />
              <small className="error">{formErrors.action_deadline}</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="action_revised_deadline">
              <Form.Label>Revised Deadline</Form.Label>
              <Form.Control
                type="date"
                name="action_revised_deadline"
                autoComplete="off"
                value={formValues.action_revised_deadline}
                onChange={handleChange}
                min={formValues.action_deadline}
                max={
                  project.project_revised_end_date
                    ? project.project_revised_end_date
                    : project.project_end_date
                }
              />
              <small className="error">
                {formErrors.action_revised_deadline}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="action_actual_deadline">
              <Form.Label>Actual Deadline</Form.Label>
              <Form.Control
                type="date"
                name="action_actual_deadline"
                autoComplete="off"
                value={formValues.action_actual_deadline}
                onChange={handleChange}
                min={formValues.action_deadline}
                max={
                  project.project_revised_end_date
                    ? project.project_revised_end_date
                    : project.project_end_date
                }
              />
              <small className="error">
                {formErrors.action_actual_deadline}
              </small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="action_status">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="action_status"
                value={formValues.action_status}
                onChange={handleChange}
              >
                <option value="">Select status</option>
                <option value="In Progress">In Progress</option>
                <option value="On Hold">On Hold</option>
                <option value="Completed">Completed</option>
                <option value="Deffered">Deffered</option>
                <option value="To Be Started">To Be Started</option>
              </Form.Select>
              <small className="error">{formErrors.action_status}</small>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-2 text-end">
          <Button type="submit" className="btn btn-primary mt-2">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}

export default EditAction;
