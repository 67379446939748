import React from "react";
import { Routes, Route } from "react-router-dom";
import useAuth from "../components/auth/useAuth";

import LoginScreen from "../screens/authentication/LoginScreen";
import ProfileScreen from "../screens/ProfileScreen";
import DashboardScreen from "../screens/DashboardScreen";

//Projects
import ProjectScreen from "../screens/projects/ProjectScreen";
import NewProjectScreen from "../screens/projects/NewProjectScreen";
import ViewProjectScreen from "../screens/projects/ViewProjectScreen";
import EditProjectScreen from "../screens/projects/EditProjectScreen";

//Activities
import NewActivityScreen from "../screens/activities/NewActivityScreen";
import ViewActivityScreen from "../screens/activities/ViewActivityScreen";
import EditActivityScreen from "../screens/activities/EditActivityScreen";

//Risks
import NewRiskScreen from "../screens/risks/NewRiskScreen";
import ViewRiskScreen from "../screens/risks/ViewRiskScreen";
import EditRiskScreen from "../screens/risks/EditRiskScreen";

//Issues
import NewIssuesScreen from "../screens/issues/NewIssuesScreen";
import ViewIssueScreen from "../screens/issues/ViewIssueScreen";
import EditIssuesScreen from "../screens/issues/EditIssuesScreen";

//Actions
import NewActionScreen from "../screens/actions/NewActionScreen";
import ViewActionScreen from "../screens/actions/ViewActionScreen";
import EditActionScreen from "../screens/actions/EditActionScreen";

//Users
import UserScreen from "../screens/user/UserScreen";
import NewUserScreen from "../screens/user/NewUserScreen";
import ViewUserScreen from "../screens/user/ViewUserScreen";
import EditUserScreen from "../screens/user/EditUserScreen";

//Roles & Permissions
import RoleScreen from "../screens/roles/RoleScreen";
import NewRoleScreen from "../screens/roles/NewRoleScreen";
import ViewRoleScreen from "../screens/roles/ViewRoleScreen";
import EditRoleScreen from "../screens/roles/EditRoleScreen";
import PermissionsScreen from "../screens/roles/PermissionsScreen";

//Error
import NotFoundScreen from "../screens/NotFoundScreen";

export default function Router(props) {
  const { isAuthenticated } = useAuth();
  const user = JSON.parse(localStorage.getItem("pmu_user"));

  return (
    <>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route exact path="/" element={<LoginScreen {...props} />} />
          </>
        ) : (
          <>
            {/* Roles & Permissions */}
            {["SUPERADMIN", "ADMIN"].includes(user.role) && (
              <>
                <Route path="/roles" element={<RoleScreen {...props} />} />
                <Route
                  path="/roles/new"
                  element={<NewRoleScreen {...props} />}
                />
                <Route
                  path="/roles/edit/:role_ref"
                  element={<EditRoleScreen {...props} />}
                />
                <Route
                  path="/roles/view/:role_ref"
                  element={<ViewRoleScreen {...props} />}
                />
                <Route
                  path="/roles/permissions"
                  element={<PermissionsScreen {...props} />}
                />

                {/* Users */}
                <Route path="/users" element={<UserScreen {...props} />} />
                <Route
                  path="/users/new"
                  element={<NewUserScreen {...props} />}
                />
                <Route
                  path="/users/edit/:user_ref"
                  element={<EditUserScreen {...props} />}
                />
                <Route
                  path="/users/view/:user_ref"
                  element={<ViewUserScreen {...props} />}
                />
              </>
            )}
            <Route
              path="/profile/:user_ref"
              element={<ProfileScreen {...props} />}
            />
            <Route path="/dashboard" element={<DashboardScreen {...props} />} />

            {/* Projects */}
            <Route path="/projects" element={<ProjectScreen {...props} />} />
            <Route
              path="/projects/new"
              element={<NewProjectScreen {...props} />}
            />
            <Route
              path="/projects/view/:project_ref"
              element={<ViewProjectScreen {...props} />}
            />
            <Route
              path="/projects/Edit/:project_ref"
              element={<EditProjectScreen {...props} />}
            />

            {/* Activities */}
            <Route
              path="/activities/new"
              element={<NewActivityScreen {...props} />}
            />
            <Route
              path="/activities/view/:activity_ref"
              element={<ViewActivityScreen {...props} />}
            />
            <Route
              path="/activities/Edit/:activity_ref"
              element={<EditActivityScreen {...props} />}
            />

            {/* Risks */}
            <Route
              path="/risks/view/:risk_ref"
              element={<ViewRiskScreen {...props} />}
            />
            <Route
              path="/risks/edit/:risk_ref"
              element={<EditRiskScreen {...props} />}
            />
            <Route path="/risks/new" element={<NewRiskScreen {...props} />} />

            {/* Issues */}
            <Route
              path="/issues/new"
              element={<NewIssuesScreen {...props} />}
            />
            <Route
              path="/issues/edit/:issue_ref"
              element={<EditIssuesScreen {...props} />}
            />
            <Route
              path="/issues/view/:issue_ref"
              element={<ViewIssueScreen {...props} />}
            />

            {/* Actions */}
            <Route
              path="/actions/new"
              element={<NewActionScreen {...props} />}
            />
            <Route
              path="/actions/view/:action_ref"
              element={<ViewActionScreen {...props} />}
            />
            <Route
              path="/actions/edit/:action_ref"
              element={<EditActionScreen {...props} />}
            />

            {/* Error */}
            <Route path="*" element={<NotFoundScreen />} />
          </>
        )}
      </Routes>
    </>
  );
}
