import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import { API_RISKS } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function RiskCount(props) {
  const [riskCount, setRiskCount] = useState(0);

  useEffect(() => {
    fetchRisks();
  }, []);

  const fetchRisks = () => {
    props
      .callRequest("GET", API_RISKS, true, null)
      .then((res) => {
        setRiskCount(res.data.count);
      })
      .catch((e) => {
        showDialog("error", e.response.data.message, 2000);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="row d-flex align-items-center">
            <div className="col-4">
              <i className="las la-exclamation-triangle card-icon-lg"></i>
            </div>
            <div className="col-8">
              <h4 className="text-muted fw-bold m-0">Risks</h4>
              <h2 className="fw-bolder m-0">{riskCount}</h2>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default RiskCount;
