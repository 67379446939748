import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import {
  API_LISTS_DEPARTMENT,
  API_LISTS_ROLE,
  API_USER_CREATE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function NewUserForm(props) {
  const navigate = useNavigate();
  const initialValues = {
    fullname: "",
    email_official: "",
    email_personal: "",
    phone: "",
    phone2: "",
    u_role_id: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);

  const validateUserSubmit = () => {
    const {
      fullname,
      email_official,
      email_personal,
      phone,
      u_role_id,
    } = formValues;
    const errors = {};
    let isValid = true;

    if (fullname === "") {
      isValid = false;
      errors.fullname = "Full Name is required";
    }
    if (phone === "") {
      isValid = false;
      errors.phone = "Phone number is required";
    }
    if (email_official === "") {
      isValid = false;
      errors.email_official = "Official email is required";
    }
    if (email_personal === "") {
      isValid = false;
      errors.email_personal = "Personal email is required";
    }
    if (u_role_id === "") {
      isValid = false;
      errors.u_role_id = "Role is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    if (name === "u_role_id") {
      setFormValues({ ...formValues, u_role_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    if (!validateUserSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_USER_CREATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 3000);
        navigate("/users");
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  useEffect(() => {
    fetchRoleOptionsData()
  }, []);

  const fetchRoleOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_ROLE, true, null)
      .then((res) => {
        setRoleOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Form onSubmit={handleUserSubmit}>
        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="fullname">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                name="fullname"
                type="text"
                value={formValues.fullname}
                placeholder="Enter Full Name"
                autoComplete="off"
                onChange={handleUserChange}
              ></Form.Control>
              <small className="error">{formErrors.fullname}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formValues.phone}
                placeholder="Enter Phone Number"
                onChange={handleUserChange}
                maxLength={10}
                autoComplete="off"
              />
              <small className="error">{formErrors.phone}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="phone2">
              <Form.Label>Alternate Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone2"
                value={formValues.phone2}
                placeholder="Enter Phone Number"
                onChange={handleUserChange}
                maxLength={10}
                autoComplete="off"
              />
              <small className="error">{formErrors.phone2}</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="email_official">
              <Form.Label>Official Email ID</Form.Label>
              <Form.Control
                type="text"
                name="email_official"
                value={formValues.email_official}
                placeholder="Enter official email ID"
                onChange={handleUserChange}
              />
              <small className="error">{formErrors.email_official}</small>
            </Form.Group>
          </Col>

          <Col lg={4}>
            <Form.Group controlId="email_personal">
              <Form.Label>Personal Email ID</Form.Label>
              <Form.Control
                type="text"
                name="email_personal"
                value={formValues.email_personal}
                placeholder="Enter personal email ID"
                onChange={handleUserChange}
              />
              <small className="error">{formErrors.email_personal}</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={4}>
            <Form.Group controlId="u_role_id">
              <Form.Label>Role</Form.Label>
              <select
                name="u_role_id"
                value={formValues.u_role_id}
                onChange={handleUserChange}
                class="form-select"
              >
                <option value="">Select role</option>
                {Object.keys(roleOptions).map((key) => (
                  <option key={key} value={key}>
                    {roleOptions[key]}
                  </option>
                ))}
              </select>
              <small className="error">{formErrors.u_role_id}</small>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-2 text-end">
          <Button type="submit" className="btn btn-primary mt-2">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}

export default NewUserForm;
