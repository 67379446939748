import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MobileLogo from "../components/common/MobileLogo";
import Sidebar from "../components/common/Sidebar";
import Topbar from "../components/common/Topbar";
import useAuth from "../components/auth/useAuth";

function ProfileScreen(props) {
  const [userInfo, setUserInfo] = useState(null);
  const [showTopbar, setShowTopbar] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);

  const handleShowTopbar = () => {
    setShowTopbar(!showTopbar);
  };
  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleChangeIcon = () => {
    setChangeIcon(!changeIcon);
  };

  const sidebarRef = useRef();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    let clickToCloseSidebar = (e) => {
      if (sidebarRef.current.contains(e.target)) {
        setShowSidebar(false);
        // console.log(sidebarRef.current)
      }
    };

    document.addEventListener("mousedown", clickToCloseSidebar);

    if (localStorage.getItem("pmu_user") != null) {
      setUserInfo(JSON.parse(localStorage.getItem("pmu_user")));
    }

    return () => {
      document.removeEventListener("mousedown", clickToCloseSidebar);
    };
  }, []);

  return (
    <>
      <MobileLogo
        handleShowTopbar={handleShowTopbar}
        changeIcon={changeIcon}
        handleChangeIcon={handleChangeIcon}
        handleShowSidebar={handleShowSidebar}
      />

      <Sidebar
        showSidebar={showSidebar}
        handleShowSidebar={handleShowSidebar}
      />

      <Topbar showTopbar={showTopbar} />

      <div className="pc-container" ref={sidebarRef}>
        <div className="pcoded-content">
          <div className="page-header">
            <div className="page-block">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="page-header-title">
                    <h5 className="m-b-10">Profile</h5>
                  </div>
                </div>
                <div className="col-md-6 text-end">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">Profile</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5>View Profile</h5>
                  <div>
                    <Link
                      to={`/profile/edit/${userInfo?.ref_code}`}
                      className="link-action btn btn-primary"
                    >
                      Edit Profile
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <a href="#">
                          <img
                            src={userInfo?.profile}
                            alt={userInfo?.fullname}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="profile-info-left pb-3">
                            <h3 className="user-name m-t-0 mb-0">
                              {userInfo?.fullname}
                            </h3>
                            <small className="text-muted fw-bold">
                              {userInfo?.designation}
                            </small>
                            <div className="staff-id">
                              Employee ID : {userInfo?.employee_code}
                            </div>
                            <div className="text-muted fw-bold">
                              {userInfo?.department}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">
                                <a href={`tel:${userInfo?.phone_1}`}>
                                  {userInfo?.phone_1}
                                </a>
                                &nbsp;/&nbsp;
                                <a href={`tel:${userInfo?.phone_2}`}>
                                  {userInfo?.phone_2}
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                {userInfo?.office_email}
                                <br />
                                {userInfo?.personal_email}
                              </div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">{userInfo?.dob}</div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">{userInfo?.gender}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileScreen;
