import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import MobileLogo from "../../components/common/MobileLogo";
import Sidebar from "../../components/common/Sidebar";
import Topbar from "../../components/common/Topbar";
import useAuth from "../../components/auth/useAuth";

import NewUserForm from "../../components/user/NewUserForm";

const NewUserScreen = (props) => {
  const navigate = useNavigate();
  const [showTopbar, setShowTopbar] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [changeIcon, setChangeIcon] = useState(false);

  const handleShowTopbar = () => {
    setShowTopbar(!showTopbar);
  };
  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleChangeIcon = () => {
    setChangeIcon(!changeIcon);
  };

  const sidebarRef = useRef();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    let clickToCloseSidebar = (e) => {
      if (sidebarRef.current.contains(e.target)) {
        setShowSidebar(false);
        // console.log(sidebarRef.current)
      }
    };

    document.addEventListener("mousedown", clickToCloseSidebar);
    return () => {
      document.removeEventListener("mousedown", clickToCloseSidebar);
    };
  }, []);

  return (
    <>
      <MobileLogo
        handleShowTopbar={handleShowTopbar}
        changeIcon={changeIcon}
        handleChangeIcon={handleChangeIcon}
        handleShowSidebar={handleShowSidebar}
      />

      <Sidebar
        showSidebar={showSidebar}
        handleShowSidebar={handleShowSidebar}
      />

      <Topbar showTopbar={showTopbar} />

      <div class="pc-container" ref={sidebarRef}>
        <div class="pcoded-content">
          <div class="page-header">
            <div class="page-block">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="page-header-title">
                    <h5 class="m-b-10">User</h5>
                  </div>
                </div>
                <div class="col-md-6 text-end">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/dashboard"}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={"/users"}>Users</Link>
                    </li>
                    <li className="breadcrumb-item">New User</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-header">
                  <h5>New User</h5>
                  <div>
                    <Button
                      className="link-action"
                      onClick={() => navigate("/users")}
                    >
                      Back to users
                    </Button>
                  </div>
                </div>
                <div class="card-body">
                  <NewUserForm {...props}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUserScreen;
