import "./App.css";

import Common from "./helpers/common";
import Router from "./router/Router";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

function App(props) {
  return (
    <div className="App">
      <Router {...props} />

      {AddLibrary("/assets/js/popper.min.js")}
      {AddLibrary("/assets/js/perfect-scrollbar.min.js")}
    </div>
  );
}

export default Common(App);
