import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import { API_PROJECTS } from "../../config/ApiList";
import { getExcerpt, getFormatedDateTime } from "../../helpers/util";

function ListProject(props) {
  const navigate = useNavigate();
  const [loadProjects, setLoadProjects] = useState(false);
  const [projectData, setProjectData] = useState([]);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = () => {
    props
      .callRequest("GET", API_PROJECTS, true, null)
      .then((res) => {
        setLoadProjects(true);
        setProjectData(res.data.projects);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5>List of Projects</h5>
          {user.role === "LEADER" ||
          user.role === "DEPARTMENT PROJECT MEMBER" ||
          user.role === "PMU PROJECT MEMBER" ? null : (
            <div>
              <Button
                className="link-action"
                onClick={() => navigate("/projects/new")}
              >
                New Project
              </Button>
            </div>
          )}
        </div>
        <div className="card-body">
          {loadProjects ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Managed By</th>
                  <th>Deadline</th>
                  <th>CAF (Cr.)</th>
                  {user.role === "SUPERADMIN" ||
                  user.role === "ADMIN" ||
                  user.role === "DEPARTMENT PROJECT MEMBER" ? (
                    <th>Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {projectData.length > 0 ? (
                  <>
                    {projectData.map((project, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div
                                className="rag-status-thumb me-2"
                                style={{
                                  backgroundColor: `${project.project_rag_status}`,
                                }}
                              ></div>
                              <span className="fs-5 fw-bold">
                                <Link
                                  to={`/projects/view/${project.project_ref}`}
                                >
                                  {getExcerpt(project.project_title, 40)}
                                </Link>
                              </span>
                            </div>

                            {/* <span className="badge bg-secondary ms-2">
                              {project.project_status}
                            </span> */}
                            <br />
                            <div className="d-flex align-items-center">
                              <i className="las la-landmark me-2 fs-6 text-muted"></i>
                              {project.project_department.d_name}
                            </div>
                            <div className="d-flex align-items-center">
                              <i className="las la-building me-2 fs-6 text-muted"></i>
                              {project.project_agency.da_name}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="col-2 text-muted">
                                <i className="las la-user-tie"></i>
                              </div>
                              <div
                                className="col-10 ms-2"
                                data-toggle="tooltip"
                                title={`${project.project_spoc.u_fullname}`}
                              >
                                {project.project_spoc.u_fullname}
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="col-2 text-muted">
                                <i className="las la-user-alt"></i>
                              </div>
                              <div
                                className="col-10 ms-2"
                                data-toggle="tooltip"
                                title={`${project.project_lead.u_fullname}`}
                              >
                                {project.project_lead.u_fullname}
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="col-2 text-muted">
                                <i className="las la-user"></i>
                              </div>
                              <div
                                className="col-10 ms-2"
                                data-toggle="tooltip"
                                title={`${project.project_manager.u_fullname}`}
                              >
                                {project.project_manager.u_fullname}
                              </div>
                            </div>
                          </td>
                          <td>
                            {project.project_actual_end_date
                              ? getFormatedDateTime(
                                  project.project_actual_end_date,
                                  "DD-MMM-YYYY"
                                )
                              : project.project_revised_end_date
                              ? getFormatedDateTime(
                                  project.project_revised_end_date,
                                  "DD-MMM-YYYY"
                                )
                              : getFormatedDateTime(
                                  project.project_end_date,
                                  "DD-MMM-YYYY"
                                )}
                          </td>
                          <td>{project.project_total_amount}</td>
                          {user.role === "SUPERADMIN" ||
                          user.role === "ADMIN" ||
                          user.role === "DEPARTMENT PROJECT MEMBER" ? (
                            <td className="text-start">
                              <Link
                                to={`/projects/edit/${project.project_ref}`}
                              >
                                <i className="las la-edit"></i>
                              </Link>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td>No project found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </>
  );
}

export default ListProject;
