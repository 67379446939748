import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  project: {},
  loading: false,
  error: null,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectDetails: (state, actions) => {
      state.project = actions.payload;
    },
  },
});

export const { setProjectDetails } = projectSlice.actions;

export default projectSlice.reducer;
