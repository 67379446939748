import { useEffect, useState } from "react";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import {
  API_LISTS_ROLE,
  API_LISTS_URLGROUP,
  API_ROLES_PERMISSIONS,
  API_ROLES_PERMISSIONS_SAVE,
} from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

function Permissions(props) {
  const { state } = useLocation();
  const roleId = state !== null && state.roleId ? state.roleId : "";
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    u_role_id: "",
    url_group_id: "",
    url_id: [],
  });

  useEffect(() => {
    setFormValues({ ...formValues, u_role_id: roleId });
  }, [roleId]);

  const [formErrors, setFormErrors] = useState({});
  const [roleOptions, setRoleOptions] = useState({});
  const [urlOptions, setUrlOptions] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [showButton, setShowButton] = useState(true);

  const validateRoleSubmit = () => {
    const { u_role_id, url_group_id } = formValues;
    const errors = {};
    let isValid = true;

    if (u_role_id === "") {
      isValid = false;
      errors.u_role_id = "Role is required";
    }
    if (url_group_id === "") {
      isValid = false;
      errors.url_group_id = "URL Group is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "u_role_id") {
      setFormValues({ ...formValues, u_role_id: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleUrlGroupChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePermissionDataSubmit = (e) => {
    e.preventDefault();
    const { u_dept_id, ...restData } = formValues;
    const formData = new FormData();
    for (let [key, value] of Object.entries(restData)) {
      formData.append(key, value);
    }
    props
      .callRequest("POST", API_ROLES_PERMISSIONS_SAVE, true, formData)
      .then((res) => {
        showDialog("success", res.data.message, 3000);
        navigate("/roles");
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.res.data.message, 5000);
      });
  };

  useEffect(() => {
    fetchRoleOptionsData();
    fetchUrlOptionsData();
  }, []);

  const fetchRoleOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_ROLE, true, null)
      .then((res) => {
        setRoleOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  const fetchUrlOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_URLGROUP, true, null)
      .then((res) => {
        setUrlOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  const fetchPermissionsData = () => {
    const query_params = `?u_role_id=${formValues.u_role_id}&url_group_id=${formValues.url_group_id}`;
    props
      .callRequest("GET", `${API_ROLES_PERMISSIONS + query_params}`, true, null)
      .then((res) => {
        setPermissionsData(res.data.permissions);
        setShowButton(false);

        let url_id = [];
        res.data.permissions.map((item) => {
          if (item.has_access === 1) {
            url_id.push(item.url_id);
          }
          return url_id;
        });

        setFormValues({ ...formValues, url_id: url_id });
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  const handleCheckboxChange = (e, id) => {
    let temp_state = permissionsData.map((item) => {
      if (item.url_id === id) {
        item.has_access = e.target.checked ? 1 : 0;
      }
      return item;
    });
    setPermissionsData(temp_state);

    let url_id = [];
    temp_state.map((item) => {
      if (item.has_access === 1) {
        url_id.push(item.url_id);
      }
      return url_id;
    });
    setFormValues({ ...formValues, url_id: url_id });
  };

  return (
    <>
      <Form onSubmit={handlePermissionDataSubmit}>
        <Row className="mb-2">
          <Col lg={4}>
            <Form.Group controlId="u_role_id">
              <Form.Label>Role</Form.Label>
              <Form.Select
                name="u_role_id"
                value={formValues.u_role_id}
                onChange={handleDataChange}
                disabled={roleId ? true : false}
              >
                <option value="">Select role</option>
                {Object.keys(roleOptions).map((key) => (
                  <option key={key} value={key}>
                    {roleOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.u_role_id}</small>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group controlId="url_group_id">
              <Form.Label>URL Group</Form.Label>
              <Form.Select
                name="url_group_id"
                value={formValues.url_group_id}
                onChange={handleUrlGroupChange}
              >
                <option value="">Select URL Group</option>
                {Object.keys(urlOptions).map((key) => (
                  <option key={key} value={urlOptions[key]}>
                    {urlOptions[key]}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.url_group_id}</small>
            </Form.Group>
          </Col>

          <Col>
            <div>
              <Button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: "1.7rem" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!validateRoleSubmit()) {
                    return false;
                  }
                  if (
                    formValues.u_role_id !== "" &&
                    formValues.url_group_id !== ""
                  ) {
                    fetchPermissionsData();
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Table responsive striped>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              {permissionsData.map((permission, i) => {
                return (
                  <tr key={permission.url_id}>
                    <td>
                      <input
                        type="checkbox"
                        name="url_id"
                        checked={permission.has_access === 1 ? true : false}
                        onChange={(e) =>
                          handleCheckboxChange(e, permission.url_id)
                        }
                      />
                    </td>
                    <td>{permission.url_name}</td>
                    <td>{permission.url_description}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                className="btn btn-primary mt-2"
                style={{ display: showButton ? "none" : "block" }}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Permissions;
