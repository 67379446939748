import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { API_RISKS_BY_PROJECT } from "../../../config/ApiList";
import {
  showDialog,
  getRiskSeverity,
  getFormatedDateTime,
  getExcerpt,
} from "../../../helpers/util";

function ProjectRisk(props) {
  const { projectRef } = props;

  const [loadRisks, setLoadRisks] = useState(false);
  const [riskData, setRiskData] = useState([]);
  const [riskCount, setRiskCount] = useState(0);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchRisks();
  }, []);

  const fetchRisks = () => {
    props
      .callRequest(
        "GET",
        API_RISKS_BY_PROJECT + "/" + props.projectRef,
        true,
        null
      )
      .then((res) => {
        setLoadRisks(true);
        setRiskCount(res.data.count);
        setRiskData(res.data.risks);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Risk</th>
            <th>Identified On</th>
            <th>Target Closure</th>
            <th>Impact</th>
            <th>Status</th>
            {user.role === "LEADER" || user.role === "ADMIN" ? null : (
              <th>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {riskCount > 0 ? (
            riskData.map((risk, i) => {
              return (
                <tr key={i}>
                  <td>
                    <span className="fs-5 fw-bold">
                      <Link
                        to={`/risks/view/${risk.risk_ref}`}
                        state={{ projectRef: projectRef }}
                      >
                        {getExcerpt(risk.risk_name, 30)}
                      </Link>
                    </span>
                  </td>
                  <td>
                    {getFormatedDateTime(
                      risk.risk_identify_date,
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>
                    {risk.risk_actual_date
                      ? getFormatedDateTime(
                          risk.risk_actual_date,
                          "DD-MMM-YYYY"
                        )
                      : risk.risk_revise_date
                      ? getFormatedDateTime(
                          risk.risk_revise_date,
                          "DD-MMM-YYYY"
                        )
                      : getFormatedDateTime(
                          risk.risk_target_date,
                          "DD-MMM-YYYY"
                        )}
                  </td>
                  <td>{getRiskSeverity(risk.risk_factor)}</td>
                  <td>{risk.risk_status}</td>
                  {user.role === "LEADER" || user.role === "ADMIN" ? null : (
                    <td className="text-start">
                      <Link to={`/risks/edit/${risk.risk_ref}`}>
                        <i className="las la-edit"></i>
                      </Link>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No risk found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ProjectRisk;
