import { Link } from "react-router-dom";

function Sidebar(props) {
  const { showSidebar } = props;
  const user = JSON.parse(localStorage.getItem("pmu_user"));

  return (
    <nav
      className={showSidebar ? "pc-sidebar mob-sidebar-active" : "pc-sidebar"}
    >
      <div className="navbar-wrapper">
        <div className="m-header">
          <a href="/dashboard" className="b-brand">
            <img
              src="/assets/images/logo.webp"
              alt=""
              className="logo logo-lg"
            />
          </a>
        </div>
        <div className="navbar-content" style={{ overflowY: "scroll" }}>
          <li className="pc-item pc-caption">
            <label>&nbsp;</label>
          </li>

          <li className="pc-item pc-hasmenu active">
            <Link to="/dashboard" className="pc-link ">
              <div className="d-flex align-items-center">
                <i className="las la-chart-bar"></i>
                <span className="ms-3">Dashboard</span>
              </div>
            </Link>
          </li>
          <div className="accordion" id="accordionExample">
            <li className="pc-item pc-hasmenu">
              <a className="pc-link ">
                <div className="d-flex align-items-center">
                  <i className="las la-laptop-code"></i>
                  <span
                    className="ms-3"
                    data-bs-toggle="collapse"
                    href="#collapseExample1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Projects
                    <span className="pc-arrow" style={{ marginLeft: "6.5rem" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-right"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </span>
                  </span>
                </div>
              </a>
              <ul
                className="pc-submenu accordion-collapse  collapse"
                id="collapseExample1"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <li className="pc-item">
                  <Link to="/projects" className="pc-link">
                    All Projects
                  </Link>
                </li>
                {user.role === "SUPERADMIN" || user.role === "ADMIN" ? (
                  <li className="pc-item">
                    <Link to="/projects/new" className="pc-link">
                      New Project
                    </Link>
                  </li>
                ) : null}
              </ul>
            </li>

            {["SUPERADMIN", "ADMIN"].includes(user.role) && (
              <>
                <li className="pc-item pc-hasmenu">
                  <a className="pc-link ">
                    <div className="d-flex align-items-center">
                      <i className="las la-user"></i>
                      <span
                        className="ms-3"
                        data-bs-toggle="collapse"
                        href="#collapseExample4"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Users
                        <span
                          className="pc-arrow"
                          style={{ marginLeft: "7.3rem" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-right"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </a>
                  <ul
                    className="pc-submenu accordion-collapse  collapse"
                    id="collapseExample4"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <li className="pc-item">
                      <Link to="/users" className="pc-link">
                        All Users
                      </Link>
                    </li>
                    <li className="pc-item">
                      <Link to="/users/new" className="pc-link">
                        New User
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="pc-item pc-hasmenu">
                  <a className="pc-link ">
                    <div className="d-flex align-items-center">
                      <i className="las la-lock"></i>
                      <span
                        className="ms-3"
                        data-bs-toggle="collapse"
                        href="#collapseExample5"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Roles & Permissions
                        <span
                          className="pc-arrow"
                          style={{ marginLeft: "2rem" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-right"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </a>
                  <ul
                    className="pc-submenu accordion-collapse  collapse"
                    id="collapseExample5"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <li className="pc-item">
                      <Link to="/roles" className="pc-link">
                        All Roles
                      </Link>
                    </li>
                    <li className="pc-item">
                      <Link to="/roles/new" className="pc-link">
                        New Role
                      </Link>
                    </li>
                    <li className="pc-item">
                      <Link to="/roles/permissions" className="pc-link">
                        Permissions
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </div>

          {/* <li className="pc-item pc-hasmenu">
            <Link to="/risks" className="pc-link ">
              <div className="d-flex align-items-center">
                <i className="las la-bug"></i>
                <span className="ms-3">Risks</span>
              </div>
            </Link>
          </li> */}

          {/* <li className="pc-item pc-hasmenu">
              <Link to="/dependencies" className="pc-link ">
                <div className="d-flex align-items-center">
                  <i className="las la-project-diagram"></i>
                  <span className="ms-3">Dependencies</span>
                </div>
              </Link>
            </li> */}

          {/* </ul> */}
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
