import { useState } from "react";
import { Row, Col } from 'react-bootstrap';
import MobileLogo from "../components/common/MobileLogo";
import Sidebar from "../components/common/Sidebar";
import Topbar from "../components/common/Topbar";

function NotFoundScreen() {
    const [showTopbar, setShowTopbar] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [changeIcon, setChangeIcon] = useState(false);
    
    const handleShowTopbar = () => {
      setShowTopbar(!showTopbar);
    };
    const handleShowSidebar = () => {
      setShowSidebar(!showSidebar);
    };
    const handleChangeIcon = () => {
      setChangeIcon(!changeIcon);
    };

    return (
        <>
          <MobileLogo
            handleShowTopbar={handleShowTopbar}
            changeIcon={changeIcon}
            handleChangeIcon={handleChangeIcon}
            handleShowSidebar={handleShowSidebar}
          />

          <Sidebar showSidebar={showSidebar} handleShowSidebar={handleShowSidebar} />

          <Topbar showTopbar={showTopbar} />

          <div className="pc-container">
            <div className="pcoded-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="page-header-title">
                        <h5 className="m-b-10">Page Not Found</h5>
                      </div>
                    </div>
                    <div className="col-md-6 text-end">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">&nbsp;</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <Row>
                          <Col md={6} xs={12}>
                          <img
                            src="/assets/images/img_404.png"
                            alt="user-image"
                            className=""
                          />
                          </Col>

                          <Col md={6} xs={12}>
                              <div className="mt-5 py-5">
                              <h1 className="h3">Awww...Don't Cry</h1>
                              <p>It's just a 404 error!</p>
                              <p className="my-5">The page you are looking for does not exist.</p>
                          </div>
                          </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );    
}

export default NotFoundScreen;