import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { API_LISTS_USER, API_ACTION_CREATE } from "../../config/ApiList";
import { showDialog } from "../../helpers/util";

const NewActionForm = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const projectRef = state.projectRef;

  const projectStartDate = state.projectStartDate;
  const projectEndDate = state.projectEndDate;
  const projectRevisedEndDate = state.projectRevisedEndDate;

  const [formValues, setFormValues] = useState({
    action_owner: "",
    action_deadline: "",
    action_description: "",
    project_ref: projectRef,
  });

  const [formErrors, setFormErrors] = useState({});
  const [ownerOptions, setOwnerOptions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateActionSubmit = () => {
    const { action_owner, action_deadline, action_description } = formValues;
    const errors = {};
    let isValid = true;

    if (action_owner === "") {
      isValid = false;
      errors.action_owner = "Owner is required";
    }

    if (action_deadline === "") {
      isValid = false;
      errors.action_deadline = "Deadline is required";
    }

    if (action_description === "") {
      isValid = false;
      errors.action_description = "Action description is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  useEffect(() => {
    fetchOwnerOptionsData();
  }, []);

  const fetchOwnerOptionsData = () => {
    props
      .callRequest("GET", API_LISTS_USER, true, null)
      .then((res) => {
        setOwnerOptions(res.data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleActionSubmit = (e) => {
    e.preventDefault();
    if (!validateActionSubmit()) {
      return false;
    }

    props
      .callRequest("POST", API_ACTION_CREATE, true, formValues)
      .then((res) => {
        showDialog("success", res.data.message, 5000);
        navigate(`/projects/view/${projectRef}`);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.response.data.message, 5000);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>New Action</h5>
              <div>
                <Button
                  className="link-action"
                  onClick={() => navigate(`/projects/view/${projectRef}`)}
                >
                  Back to Projects
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form onSubmit={handleActionSubmit}>
        <Row className="mb-3">
          <Col lg={12}>
            <Form.Group controlId="action_description">
              <Form.Label>Action Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="action_description"
                placeholder="Enter Action description"
                autoComplete="off"
                value={formValues.action_description}
                onChange={handleChange}
              />
              <small className="error">{formErrors.action_description}</small>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="action_owner">
              <Form.Label>Action Owner</Form.Label>
              <Form.Select
                name="action_owner"
                autoComplete="off"
                value={formValues.action_owner}
                onChange={handleChange}
              >
                <option value="">Select Owner</option>
                {ownerOptions.map((key) => (
                  <option key={key.u_id} value={key.u_id}>
                    {key.u_fullname}
                  </option>
                ))}
              </Form.Select>
              <small className="error">{formErrors.action_owner}</small>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group controlId="action_deadline">
              <Form.Label>Action Deadline</Form.Label>
              <Form.Control
                type="date"
                name="action_deadline"
                autoComplete="off"
                value={formValues.action_deadline}
                onChange={handleChange}
                min={projectStartDate}
                max={
                  projectRevisedEndDate ? projectRevisedEndDate : projectEndDate
                }
              />
              <small className="error">{formErrors.action_deadline}</small>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-2 text-end">
          <Button type="submit" className="btn btn-primary mt-2">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewActionForm;
