import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { API_ACTIVITIES } from "../../../config/ApiList";
import { showDialog, getFormatedDateTime } from "../../../helpers/util";

function ProjectActivity(props) {
  const { projectRef } = props;

  const [loadActivities, setLoadActivities] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [activityCount, setActivityCount] = useState(0);

  const user = JSON.parse(localStorage.getItem("pmu_user"));

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = () => {
    props
      .callRequest("GET", API_ACTIVITIES + "/" + props.projectRef, true, null)
      .then((res) => {
        setLoadActivities(true);
        setActivityCount(res.data.count);
        setActivityData(res.data.activities);
      })
      .catch((e) => {
        console.log(e);
        showDialog("error", e.data.message, 5000);
      });
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Activities</th>
            <th>Assigned to</th>
            <th>Start Date</th>
            <th>Deadline</th>
            {user.role === "LEADER" || user.role === "ADMIN" ? null : (
              <th>Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {activityCount > 0 ? (
            activityData.map((activity, i) => {
              return (
                <tr key={i}>
                  <td>
                    <span className="fs-5 fw-bold">
                      <Link
                        to={`/activities/view/${activity.activity_ref}`}
                        state={{ projectRef: projectRef }}
                      >
                        {activity.activity_name}
                      </Link>
                    </span>
                    <span className="badge bg-secondary ms-2">
                      {activity.activity_status}
                    </span>
                  </td>
                  {/* <td>
                    {activity.assign.length > 1
                      ? activity.assign[0].user.u_fullname +
                        " +" +
                        (activity.assign.length - 1)
                      : activity.assign[0].user.u_fullname}
                  </td> */}
                  <td
                    data-toggle="tooltip"
                    title={`${activity?.assign[0]?.user.u_fullname}`}
                  >
                    {activity?.assign[0]?.user.u_fullname}
                  </td>
                  <td>
                    {getFormatedDateTime(
                      activity.activity_plan_start_date,
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td>
                    {activity.activity_actual_end_date
                      ? getFormatedDateTime(
                          activity.activity_actual_end_date,
                          "DD-MMM-YYYY"
                        )
                      : activity.activity_revised_end_date
                      ? getFormatedDateTime(
                          activity.activity_revised_end_date,
                          "DD-MMM-YYYY"
                        )
                      : getFormatedDateTime(
                          activity.activity_plan_end_date,
                          "DD-MMM-YYYY"
                        )}
                  </td>
                  {user.role === "LEADER" || user.role === "ADMIN" ? null : (
                    <td className="text-left">
                      <Link to={`/activities/edit/${activity.activity_ref}`}>
                        <i className="las la-edit"></i>
                      </Link>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No activity found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ProjectActivity;
