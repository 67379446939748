import React, { Component } from "react";
import axios from "axios";
import { refresh } from "../classes/auth";
import moment from "moment";

const Common = (WrappedComponent) => {
  class common extends Component {
    state = {};

    callRequest(method, url, auth, data = "") {
      try {
        if (auth) {
          axios.defaults.headers.common["token"] =
            localStorage.getItem("pmu_token");
        }

        return new Promise((resolve, reject) => {
          axios({
            method: method,
            url: url,
            data: data,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              const status = error.response ? error.response.status : null;
              if (status === 401 && auth) {
                this.showDialog("error", error.response.data.message, 5000);
                let response = refresh(error);
                response.then((res) => {
                  resolve(res);
                });
              } else {
                reject(error);
              }
            });
        });
      } catch (e) {
        console.log("Call Request Error:", e);
      }
    }

    showDialog(type, message, timeout = 3000) {
      var classname = "flash-message alert";

      if (type === "success") {
        classname += " alert-success";
      } else if (type === "error") {
        classname += " alert-danger";
      } else if (type === "warning") {
        classname += " alert-warning";
      }

      var div = document.createElement("div");
      div.className = classname;
      div.innerHTML = message;
      document.body.appendChild(div);

      setTimeout(() => {
        var elem = document.querySelector(".flash-message");
        elem.parentNode.removeChild(elem);
      }, timeout);
    }

    showConfirmDialog(message, timeout = 5000) {
      var classname = "flash-message alert alert-warning";

      var div = document.createElement("div");
      div.className = classname;

      var content = "<div class='my-5 text-center'>";
      content += "<h6>" + message + "</h6>";
      content += "<div class='my-3'>";
      content +=
        "<button type='button' class='btn btn-sm btn-primary me-3'>Proceed</button>&nbsp;";
      content +=
        "<button type='button' class='btn btn-sm btn-secondary'>Cancel</button>";
      content += "</div>";
      content += "</div>";
      div.innerHTML = content;
      document.body.appendChild(div);

      setTimeout(() => {
        var elem = document.querySelector(".flash-message");
        elem.parentNode.removeChild(elem);
      }, timeout);
    }

    getCurrentMonth() {
      let month = moment().format("MMMM");
      return month;
    }

    getCurrentYear() {
      let year = moment().format("YYYY");
      return year;
    }

    getCurrentMonthYear() {
      let month_year = moment().format("MMMM-YYYY");
      return month_year;
    }

    getCurrentDate(dateFormat = "DD/MM/YYYY") {
      let today = moment().format(dateFormat);
      return today;
    }

    getNextDays(n = 7) {
      let days = [];
      let daysRequired = n;

      for (let i = 0; i < daysRequired; i++) {
        days.push(moment().add(i, "days").format("dddd,DD-MM-YYYY"));
      }
      return days;
    }

    getCurrentFinancialYear() {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();
      let financialYear;

      // Check if the current month is after March (which marks the end of the financial year)
      if (currentMonth >= 2) {
        financialYear = `${currentYear}-${currentYear + 1}`;
      } else {
        financialYear = `${currentYear - 1}-${currentYear}`;
      }

      return financialYear;
    }

    getFormatedDateTime(datetime, format = "hh:mm A, DD/MM/YYYY") {
      const dateTime = moment(datetime);
      const formattedDateTime = dateTime.format(format);
      return formattedDateTime;
    }

    getFormatedDate(datetime, format = "DD-MMM-YYYY") {
      const dateTime = moment(datetime);
      const formattedDateTime = dateTime.format(format);
      return formattedDateTime;
    }

    convertDate(dateString) {
      const date = new Date(dateString);

      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      const newDateString = `${day}-${month}-${year}`;

      return newDateString;
    }

    convertDateStringToYMD(dateString) {
      const date = new Date(dateString);

      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      const newDateString = `${year}-${month}-${day}`;

      return newDateString;
    }

    getDateObj(timestamp) {
      let date = new Date(timestamp * 1000),
        datevalues = {
          year: date.getFullYear(),
          month: ("0" + (date.getMonth() + 1)).slice(-2),
          date: ("0" + date.getDate()).slice(-2),
          hrs: ("0" + date.getHours()).slice(-2),
          min: ("0" + date.getMinutes()).slice(-2),
          sec: ("0" + date.getSeconds()).slice(-2),
        };

      return datevalues;
    }

    getExcerpt(text, len = 120) {
      if (text === "") {
        return text;
      } else {
        if (text.length > len) {
          let excerpt = text.substr(0, len) + " ...";
          return excerpt;
        } else {
          return text;
        }
      }
    }

    ucFirst(text) {
      const textCapitalized = text.charAt(0).toUpperCase() + text.slice(1);
      return textCapitalized;
    }

    getLowerCase(text) {
      return text.toLowerCase();
    }

    getCurrencyFormat(number) {
      let cur = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(number);

      return cur;
    }

    changeUrlToTitle(str, wordToReplace = "-", wordByReplace = " ") {
      let modified_string = "";
      const words = str.split(wordToReplace);

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      modified_string = words.join(wordByReplace);

      return modified_string;
    }

    getWeekdayNames() {
      const weekdayNames = {
        MONDAY: "MONDAY",
        TUESDAY: "TUESDAY",
        WEDNESDAY: "WEDNESDAY",
        THURSDAY: "THURSDAY",
        FRIDAY: "FRIDAY",
        SATURDAY: "SATURDAY",
        SUNDAY: "SUNDAY",
      };

      return weekdayNames;
    }

    getDuration() {
      const duration = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
      };
      return duration;
    }

    getMonth() {
      const month = {
        "01": "JANUARY",
        "02": "FEBRUARY",
        "03": "MARCH",
        "04": "APRIL",
        "05": "MAY",
        "06": "JUNE",
        "07": "JULY",
        "08": "AUGUST",
        "09": "SEPTEMBER",
        "010": "OCTOBER",
        "011": "NOVEMBER",
        "012": "DECEMBER",
      };
      return month;
    }

    generateHours() {
      const startHour = 6; // Starting hour (06:00 AM)
      const endHour = 22; // Ending hour (10:00 PM)

      const hours = [];

      for (let hour = startHour; hour <= endHour; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const currentTime = new Date().setHours(hour, minute);
          const formattedTime = new Date(currentTime).toLocaleString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          hours.push(formattedTime.toUpperCase());
        }
      }
      return hours;
    }

    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    checkAlphaOnly(item) {
      const alphaOnlyPattern = new RegExp("^[a-zA-Z .]+$");
      return alphaOnlyPattern.test(item);
    }

    checkNumericOnly(item) {
      const numericOnlyPattern = new RegExp("^[0-9 ]+$");
      return numericOnlyPattern.test(item);
    }

    handleKeyPress = (event) => {
      const keyCode = event.charCode || event.keyCode;
      if (keyCode === 0 || keyCode === 8 || keyCode === 9 || keyCode === 46)
        return true;
      if (keyCode < 48) {
        event.preventDefault();
      } else if (keyCode > 57) {
        if (keyCode < 96 || keyCode > 105) {
          event.preventDefault();
        } else {
          return true;
        }
      }
    };

    getFileType = (file) => {
      let type;
      let source;
      let regexp =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-)[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-)[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S)?$/;

      if (regexp.test(file)) {
        if (file.match(/\.(jpeg|jpg|png)$/)) {
          type = "image";
          source = file;
        } else {
          type = "video";
          source = file;
        }
      } else {
        type = file.type.split("/")[0];
        source = URL.createObjectURL(file);
      }

      return type + "~" + source;
    };

    isValidDate(dateString) {
      let regexPattern = /^\d{4}-\d{2}-\d{2}$/;
      if (!regexPattern.test(dateString)) {
        return false;
      } else {
        return true;
      }
    }

    render() {
      return (
        <WrappedComponent
          callRequest={this.callRequest}
          showDialog={this.showDialog}
          showConfirmDialog={this.showConfirmDialog}
          getCurrentMonth={this.getCurrentMonth}
          getCurrentYear={this.getCurrentYear}
          getCurrentMonthYear={this.getCurrentMonthYear}
          getCurrentDate={this.getCurrentDate}
          getNextDays={this.getNextDays}
          getCurrentFinancialYear={this.getCurrentFinancialYear}
          getFormatedDateTime={this.getFormatedDateTime}
          getFormatedDate={this.getFormatedDate}
          convertDate={this.convertDate}
          convertDateStringToYMD={this.convertDateStringToYMD}
          getDateObj={this.getDateObj}
          getExcerpt={this.getExcerpt}
          ucFirst={this.ucFirst}
          getLowerCase={this.getLowerCase}
          getCurrencyFormat={this.getCurrencyFormat}
          changeUrlToTitle={this.changeUrlToTitle}
          getWeekdayNames={this.getWeekdayNames}
          getDuration={this.getDuration}
          getMonth={this.getMonth}
          generateHours={this.generateHours}
          validateEmail={this.validateEmail}
          checkAlphaOnly={this.checkAlphaOnly}
          checkNumericOnly={this.checkNumericOnly}
          handleKeyPress={this.handleKeyPress}
          isValidDate={this.isValidDate}
          {...this.props}
        />
      );
    }
  }

  return common;
};

export default Common;
